import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Bar } from 'react-chartjs-2';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button
} from '@material-ui/core';
import palette from 'theme/palette';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getBuildActivity } from 'helpers/api';
import { getRandomColor } from 'helpers/color';

// import { data, options } from './chart';

const useStyles = makeStyles(() => ({
    root: {},
    chartContainer: {
        height: 400,
        position: 'relative'
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const BuildChart = props => {
    const { className, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();
    const [buildData, setBuildData] = useState()
    const data = {
        labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
        datasets: [
            {
                label: 'Success Build',
                backgroundColor: palette.primary.main,
                data: [18, 5, 19, 27, 29, 19, 20]
            },
            {
                label: 'Failed Build',
                backgroundColor: palette.neutral,
                data: [11, 20, 12, 29, 30, 25, 13]
            }
        ]
    };

    const fetchBuildData = async () => {
        const response = await getBuildActivity()
        const { data } = response.data;
        let d = {}
        let l = []
        let sData = []
        let fData = []
        data.forEach(e => {
            l.push(e.date)
            sData.push(e.total_success)
            fData.push(e.total_failed)
        });
        d.labels = l
        d.datasets= [
            {
                label: 'Success Build',
                backgroundColor: '#09b314',
                data: sData
            },
            {
                label: 'Failed Build',
                backgroundColor: theme.palette.error.main,
                data: fData
            }
        ]        
        console.log('D-', d)
        setBuildData(d)
        // setBuildContent(devices)
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        legend: { display: false },
        cornerRadius: 20,
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            borderWidth: 1,
            borderColor: palette.divider,
            backgroundColor: palette.white,
            titleFontColor: palette.text.primary,
            bodyFontColor: palette.text.secondary,
            footerFontColor: palette.text.secondary
        },
        layout: { padding: 0 },
        scales: {
            xAxes: [
                {
                    barThickness: 12,
                    maxBarThickness: 10,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    ticks: {
                        fontColor: palette.text.secondary
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: palette.text.secondary,
                        beginAtZero: true,
                        min: 0
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: palette.divider
                    }
                }
            ]
        }
    };


    function refresh() {
        fetchBuildData()
    }

    useEffect(() => {
        fetchBuildData()
    }, [])


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                action={
                    <Button
                        size="small"
                        variant="text"
                        style={{
                            color: '#fff'
                        }}

                    >
                        Last 7 days <ArrowDropDownIcon />
                    </Button>
                }
                title="Latest Build Activities"
            />
            <Divider />
            <CardContent>
                <div className={classes.chartContainer}>
                    <Bar
                        data={buildData === undefined ? {} : buildData}
                        options={options}
                    />
                </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
                <Button
                    //   color="primary"
                    size="small"
                    variant="text"
                    style={{
                        color: '#fff'
                    }}
                >
                    Overview <ArrowRightIcon />
                </Button>
            </CardActions>
        </Card>
    );
};

BuildChart.propTypes = {
    className: PropTypes.string
};

export default BuildChart;
