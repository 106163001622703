import { Button, Grid, Table, TableContainer, TableRow, Paper, TableHead, TableBody, TableCell } from '@material-ui/core';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TabPanel from './TabPanel';
import { createBuild } from 'helpers/api';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-terminal";

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '12px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: "#000",
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    },
    reviewItem: {
        maxHeight: '75px'
    },
    table: {
        width: "100%",
        "& thead th": {
            padding: "10px"
        },
        "& tbody td": {
            backgroundColor: "#fff",
            padding: "10px",
            marginBottom: "5px"
        }
    }
}));

const Review = props => {
    const { data, buildId, onClickNext, onClickPrev } = props;

    // data.repo = { name: 'Test Project Node' };
    // data.branch = '-';
    // console.log(data.envars);

    const classes = useStyles();
    const theme = useTheme();

    const complete = data.repo != null && data.branch != null && data.runtime != null && data.version != null && data.test != null && data.packageManager != null;

    if (!complete) {
        return <TabPanel {...props}></TabPanel>
    }

    const handleNextClick = async () => {
        try {
            if (buildId == null) {
                const { data: result } = await createBuild({
                    scmProvider: 'github',
                    gitUrl: data.repo.url,
                    gitBranch: data.branch.name,
                    icon: data.runtime.icon,
                    packageManager: data.packageManager,
                    postBuildCommand: data.postBuildCommands,
                    preBuildCommand: data.preBuildCommands,
                    runtime: data.runtime.id,
                    testFramework: data.test,
                    version: data.version,
                    automaticBuild: data.selectedTrigger
                })

                onClickNext(result.buildId)
            } else {
                onClickNext(buildId)
            }

        } catch (error) {
            console.trace(error)
        }
    }

    return (
        <TabPanel {...props}>
            <div className={classes.containerReview}>
                <Grid container>
                    <Grid item>
                        <div style={{ fontSize: '16px' }}>
                            Let’s review your build configuration:
                        </div>
                    </Grid>
                </Grid>


                <div style={{ marginTop: '50px', background: '#f5f5f5', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), borderRadius: '10px' }}>
                    <Grid container direction="column" spacing={4} className={classes.titleBuild}>
                        <Grid item className={classes.reviewItem}>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Repository provider</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>Github</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.reviewItem}>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Code repository</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>
                                        {data.repo.name}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.reviewItem}>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Version</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>
                                        {data.runtime.name} version {data.version}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Package manager</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>
                                        {data.packageManager}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Test frameworks</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>{data.test}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>Automatic Build</div>
                                </Grid>
                                <Grid item sm="4">
                                    <div className={classes.contentReview}>{data.selectedTrigger ? 'Yes' : 'No'}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>
                                        Custom commands to execute before the build process
                            </div>
                                </Grid>
                                <Grid item sm="6">
                                    {data.preBuildCommands.length ?
                                        <AceEditor
                                            width="100%"
                                            mode="sh"
                                            readOnly={true}
                                            highlightActiveLine={false}
                                            minLines={3}
                                            maxLines={5}
                                            theme="terminal"
                                            value={data.preBuildCommands.join('\n')}
                                            name="editor-prebuild"
                                            editorProps={{ $blockScrolling: true }}
                                        />
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>
                                        Custom commands to execute after the build process
                                    </div>
                                </Grid>
                                <Grid item sm="6">
                                    {data.postBuildCommands.length ?
                                        <AceEditor
                                            width="100%"
                                            mode="sh"
                                            readOnly={true}
                                            highlightActiveLine={false}
                                            minLines={3}
                                            maxLines={5}
                                            theme="terminal"
                                            value={data.postBuildCommands.join('\n')}
                                            name="editor-prebuild"
                                            editorProps={{ $blockScrolling: true }}
                                        />
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item sm="4">
                                    <div className={classes.labelReview}>
                                        Environment Variables
                                    </div>
                                </Grid>
                                <Grid item sm="6">
                                    <table cellSpacing="0" cellPadding="0" className={classes.table} aria-label="simple table">
                                        <thead style={{ backgroundColor: 'transparent' }}>
                                            <tr>
                                                <th align="left">Key</th>
                                                <th align="left">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.envars.map((row, index) => (
                                                <>
                                                    <tr key={row.key} style={{ paddingBottom: 10, backgroundColor: "#fff" }}>
                                                        <td>
                                                            {row.key}
                                                        </td>
                                                        <td>{row.value}</td>
                                                    </tr>
                                                    <tr >
                                                        <td colSpan="3" style={{ backgroundColor: "transparent", padding: "3px" }}></td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </div>
            <div style={{ marginTop: theme.spacing(5) }}>
                <Grid
                    container
                    justify="space-between">
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.btnNext}
                        onClick={() => onClickPrev()}
                        style={{ marginRight: '5px' }}>
                        <NavigateBeforeIcon />
                        <span className={classes.txtNext}>Configure</span>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleNextClick}
                        variant="contained"
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Build</span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    );
};

Review.propTypes = {
    className: PropTypes.string
};

export default Review;
