/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(1)
  },
  button: {
    color: theme.palette.iconBlack,
    padding: '10px 8px',
    justifyContent: 'space-between',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.red,
      color: theme.palette.white,
      fontWeight: 'bold',
      '& $icon': {
        fontWeight: 'bold',
        color: theme.palette.white
      }
    }
  },
  icon: {
    color: theme.palette.iconBlack,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.white
    }
  },
  pageTitle: {
    paddingLeft: '10px'
  },
  nested: {
    color: theme.palette.iconBlack,
    padding: '5px 8px',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: 'Roboto',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      fontWeight: 'bold',
      '& $icon': {
        fontWeight: 'bold'
      }
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.white
      }
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.white
      }
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));
const ListCollapse = ({ page }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const toggleOpen = () => {
    setOpen(open => !open);
  };
  return page.items ? (
    <>
      <ListItem className={classes.item} disableGutters key={page.title}>
        <Button
          //activeClassName={classes.active}
          className={classes.button}
          onClick={toggleOpen}>
          <div className={classes.icon}>
            {page.icon}
            <span className={classes.pageTitle}>{page.title}</span>
          </div>
          <div style={{ paddingTop: '8px' }}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </Button>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {page.items.map((item, i) => (
            <ListItem
              key={i}
              button
              to={item.href}
              component={CustomRouterLink}
              className={classes.nested}>
              <span style={{ padding: '16px' }}></span>
              {item.title}
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  ) : (
      <ListItem className={classes.item} disableGutters key={page.title}>
        <Button
          //activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          to={page.href}>
          <div className={classes.icon}>
            {page.icon}
            <span className={classes.pageTitle}>{page.title}</span>
          </div>
        </Button>
      </ListItem>
    );
};
const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page, i) => (
        <ListCollapse key={i} page={page} />
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
