import { Avatar, Button, Grid, Menu, MenuItem } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import { getMyBuild } from 'helpers/api';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TabPanel from './TabPanel';
import BuildDropdown from './BuildDropdown';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '14px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px',
        textAlign: 'left'
    },
    selectBuild: {
        fontSize: '12px',
        // color: '#9e9e9e',
        // paddingTop: '4px',
        textAlign: 'left'
    },    
    messageBox: {
        backgroundColor: '#000',
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    }
}));


const ChooseBuild = props => {
    const { onNext } = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null)
    const [builds, setBuilds] = useState([])
    const [selectedBuild, setSelectedBuild] = useState(null)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectBuild = (index) => {
        setSelectedBuild(index)

        setAnchorEl(null)
    }

    const handleChooseBuildClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const loadBuilds = async () => {
        try {
            const { data: result } = await getMyBuild();
            setBuilds(result.data);
        } catch (error) {
            console.trace(error)
        }
    }


    useEffect(() => {
        loadBuilds()
    }, [])

    const build = selectedBuild !== null ? builds[selectedBuild] : null

    return (
        <TabPanel {...props}>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                What would you like to deploy
            </span>
            <Grid container spacing={4} className={classes.titleBuild}>
                <Grid item sm={4}>
                    One of my active builds
                    <div className={classes.separator}></div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleChooseBuildClick}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <div style={{ display: 'flex' }}>
                                {build ? (
                                    <Avatar
                                        src={build.runtimeIcon}
                                        style={{ marginTop: '6px' }}
                                    />
                                ) : (
                                        <BuildIcon className={classes.iconBuild} />
                                    )}
                                <div className={classes.selectContainer}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                            {build ? build.name : 'Choose from my  active builds'}
                                        </div>
                                        {/* <div className={classes.badges}>YAML</div> */}
                                    </div>
                                    <div className={classes.selectCapt}>
                                        {/* {build ? build.buildId : 'Deploy an active build'} */}
                                        {build ? build.name : 'Deploy an active build'}
                                    </div>
                                </div>
                            </div>
                            <ExpandMoreIcon style={{ marginTop: '10px' }} />
                        </div>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {builds.map((build, index) =>
                        // {
                            // console.log(build.meta)
                            // let meta = JSON.parse(build.meta) 
                            // (
                                <MenuItem onClick={() => selectBuild(index)} key={index}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                        <div style={{ display: 'flex' }}>
                                            <Avatar
                                                src={build.runtimeIcon}
                                                style={{ marginTop: '6px' }}
                                            />
                                            <div className={classes.selectContainer}>
                                                <div style={{ display: 'flex' }}>
                                                    <div className={classes.selectBuild}>
                                                        <BuildDropdown runtime={build.name} meta={build.meta} key="hash" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MenuItem>
                            // )
                        // }
                        )}
                    </Menu>
                </Grid>
                <Grid item sm={4}>
                    My own build artifact
                    <div className={classes.separator}></div>
                    <div className={classes.buildOption}>
                        <div style={{ display: 'flex' }}>
                            <BuildIcon className={classes.iconBuild} />
                            <div className={classes.txtBuild}>
                                <div>Upload my build artifact</div>
                                <div
                                    style={{ fontSize: '14px', color: 'rgb(84, 110, 122)' }}>
                                    Deploy an active build
                                </div>
                            </div>
                        </div>
                        {/* <ExpandMoreIcon /> */}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.containerBtnNav}>
                <Grid container justify="flex-end">
                    <Button
                        onClick={() => onNext(build)}
                        disabled={selectedBuild == null}
                        color="primary"
                        variant="contained"
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Next </span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    );
};

ChooseBuild.propTypes = {
    className: PropTypes.string
};

export default ChooseBuild;
