import axios from 'axios'

const API_BASE = process.env.REACT_APP_API_SERVER;

const http = axios.create({
    baseURL: API_BASE
})

http.interceptors.request.use(function (config) {
    // Do something before request is sent

    const token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export function signUp({ firstname, lastname, email, password }) {
    return http.post('customer/signup', {
        firstname,
        lastname,
        email,
        password
    });
}

export function signIn(email, password) {
    return http.post('customer/login', {
        email,
        password
    })
}

export function signInGoogle(email, googleId) {
    return http.post('customer/login-google', {
        email,
        googleId
    })
}

export function signUpGoogle(email, googleId) {
    return http.post('customer/signup-google', {
        email,
        googleId
    })
}

export function getBuildList(page = 1, q = '') {
    return http.get(`build/?page=${page}&q=${q}`)
}

export function getBuildDetail(buildId) {
    return http.get(`build/detail/${buildId}`)
}

export function testBuild(data) {
    return http.post('build/test-build', data);
}

export function signUpGithub(githubAuthCode) {
    return http.post('customer/signup-github', {
        githubAuthCode
    })
}

export function getRepoList(page) {
    return http.get(`build/github-repo?page=${page}`)
}

export function getBranchList(repoName) {
    const repo = repoName.split('/')[1]
    return http.get('build/github-branch/' + repo)
}

export function runBuild(buildId) {
    return http.post('build/run', {
        buildId
    })
}

export function getBuildRuntime() {
    return http.get('build/runtime')
}

export function getBuildRuntimeDetail(id) {
    return http.get('build/runtime-detail/' + id)
}

export function githubAuthorize(githubAuthCode) {
    return http.post('build/github-authorize', {
        githubAuthCode
    })
}

export function createBuild({ scmProvider, gitUrl, gitBranch, runtime, icon, version, packageManager, testFramework, preBuildCommand = [], postBuildCommand = [], automaticBuild }) {
    return http.post('build/new', {
        scmProvider,
        gitUrl,
        gitBranch,
        runtime,
        icon,
        version,
        packageManager,
        testFramework,
        preBuildCommand,
        postBuildCommand,
        automaticBuild: automaticBuild
    })
}

export function getProviders() {
    return Promise.resolve([
        {
            id: 'github',
            name: 'Github',
            icon: '/images/github.png',
            desc: `Home to the world's largest community of developers`,
            enabled: true
        },
        {
            id: 'gitlab',
            name: 'Gitlab',
            icon: '/images/gitlab-brands.svg',
            desc: 'GitLab is a complete DevOps platform, delivered as a single application',
            enabled: false
        },
        {
            id: 'bitbucket',
            name: 'Bitbucket',
            icon: '/images/bitbucket-brands.svg',
            desc: 'Bitbucket is a web-based version control repository hosting service owned by Atlassian',
            enabled: false
        }
    ])
}

export function getCredentialStatus() {
    return http.get(`setting/verify-cloud-account`)
}

export function getDeployList(page = 1) {
    return http.get(`deploy/?page=${page}`)
}

export function getDeployDetail(deployId) {
    return http.get(`deploy/view/${deployId}`)
}

export function getMyBuild() {
    return http.get(`deploy/my-build`)
}

export function getCloudProviders() {
    return http.get('deploy/provider')
}

export function getDeployEnvironment() {
    return http.get('deploy/environment')
}

export function getDeployServices(id) {
    return http.get('deploy/service/' + id)
}

export function getDeployServiceOs(serviceId) {
    return http.get('deploy/service-os/' + serviceId)
}

export function getDeployInstanceType(providerId) {
    return http.get('deploy/provider-instance/' + providerId)
}

export function getDeployStorageType(providerId) {
    return http.get('deploy/provider-storage/' + providerId)
}

export function createDeploy({ environment, buildId, cloudProvider, cloudProviderIcon, cloudService, cloudOs, cloudInstance, cloudStorageType, cloudStorageSize, deploymentData, deploymentStrategy, cloudMinInstance, cloudMaxInstance }) {
    return http.post('deploy/new', {
        environment,
        buildId,
        cloudProvider,
        cloudProviderIcon,
        cloudService,
        cloudOs,
        cloudInstance,
        cloudStorageType,
        cloudStorageSize,
        deploymentData,
        deploymentStrategy,
        cloudMinInstance,
        cloudMaxInstance
    })
}

export function checkDeployPhase(deployId) {
    return http.get(`deploy/last-phase/${deployId}`)
}

export function runDeploy(deployId, state) {
    return http.post('deploy/run', {
        deployId,
        state
    })
}

export function getAwsRegion() {
    return http.get('helper/aws-region')
}

export function saveAwsCredential(data) {
    return http.post('customer/save-aws-cred', data)
}

export function removeAwsCredential() {
    return http.get('customer/remove-aws-cred')
}

export function getAwsCredential() {
    return http.get('customer/get-aws-cred')
}

export function getTotalBuild() {
    return http.get('dashboard/total-build')
}

export function getBuildByRuntime() {
    return http.get('dashboard/build-runtime')
}

export function getBuildActivity() {
    return http.get('dashboard/build-activity')
}

export function getMonitoringEc2(deployId) {
    return http.get(`monitoring/ec2/${deployId}`)
}

export function getMonitoringEB(deployId) {
    return http.get(`monitoring/eb/${deployId}`)
}

export function getMonitoringEcs(deployId) {
    return http.get(`monitoring/ecs/${deployId}`)
}

export function getNotification() {
    return http.get('notification/')
}

// settings
export function saveContact(data) {
    return http.post('setting/add-contact', data)
}

export function deleteContact(data) {
    return http.post('setting/delete-contact', data)
}

export async function getContactList() {
    return http.get('setting/contact')
}

export async function getNotificationPreference() {
    return http.get('setting/notification')
}

export async function updateNotificationPreference(data) {
    return http.post('/setting/update-notification', data)
}

export async function getCloudAccount() {
    return http.get('setting/cloud-account')
}

export function addCloudAccount(data) {
    return http.post('setting/add-cloud-account', data)
}
export function updateCloudAccount(data) {
    return http.post('setting/update-cloud-account', data)
}

export function deleteCloudAccount(data) {
    return http.post('setting/delete-cloud-account', data)
}