import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

// import { Activity } from './components';
// import { ActivityCard } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
        // flexGrow: 1,
    },
    container: {
        // marginTop: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        // margin: theme.spacing(3)

    },
    title: {
        marginBottom: theme.spacing(3)
    },
    group: {
        '& + &': {
            marginTop: theme.spacing(4)
        }
    },
    activity: {
        position: 'relative',
        '& + &': {
            marginTop: theme.spacing(3),
            '&:before': {
                position: 'absolute',
                content: '" "',
                height: 20,
                width: 1,
                top: -20,
                left: 20,
                backgroundColor: theme.palette.divider
            }
        }
    },

}));

const Notification = props => {
    const classes = useStyles();
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        let mounted = true;

        // const fetchOrder = () => {
        //     axios.get('/api/orders/1').then(response => {
        //         if (mounted) {
        //             setOrder(response.data.order);
        //         }
        //     });
        // };

        const fetchOrder = () => {
            if (mounted) {
                setActivity([{
                    id: 1,
                    name: 'X',
                    cuantity: 10,
                    billing: 'YY',
                    status: 'OK',
                    currency: '$',
                    value: '$',
                }, {
                    id: 2,
                    name: 'Y',
                    cuantity: 10,
                    billing: 'AAA',
                    status: 'OK',
                    currency: '$',
                    value: '$',
                }]);
            }
        };

        fetchOrder();


        return () => {
            mounted = false;
        };
    }, []);

    if (!activity) {
        return null;
    }

    return (
        <>
            <Grid
                className={classes.container}
                container
                spacing={4}
                justify="center"
            >
                 {/* <Grid
                    className={classes.content}
                    item
                    md={8}
                    xl={10}
                    xs={12}
                >
                    <Activity activity={activity} />
                </Grid> */}

                
                 
             </Grid>
        </>
    );



};

Notification.propTypes = {
    activities: PropTypes.array.isRequired,
    className: PropTypes.string
};

export default Notification;
