import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Queue from '@material-ui/icons/Queue';
import CodeIcon from '@material-ui/icons/Code';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDone from '@material-ui/icons/CloudDone';
import CloudQueue from '@material-ui/icons/CloudQueue';
import CloudOff from '@material-ui/icons/CloudOff';
import Loop from '@material-ui/icons/Loop';
import LocationDisabled from '@material-ui/icons/LocationDisabled';

import gradients from 'utils/gradients';

const useStyles = makeStyles(theme => ({
    root: {},
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    avatarBlue: {
        backgroundImage: gradients.blue
    },
    avatarGreen: {
        backgroundImage: gradients.green
    },
    avatarRed: {
        backgroundImage: gradients.red
    },

    avatarOrange: {
        backgroundImage: gradients.orange
    },
    avatarIndigo: {
        backgroundImage: gradients.indigo
    },
    arrowForwardIcon: {
        color: theme.palette.icon
    }
}));

const NotificationList = props => {
    const { notifications, className, ...rest } = props;

    const classes = useStyles();

    const avatars = {
        build_success: (
            <Avatar className={classes.avatarGreen}>
                <CodeIcon />
            </Avatar>
        ),
        build_failed: (
            <Avatar className={classes.avatarRed}>
                <LocationDisabled />
            </Avatar>
        ),
        build_running: (
            <Avatar className={classes.avatarBlue}>
                <Loop />
            </Avatar>
        ),
        build_provisioning: (
            <Avatar className={classes.avatarBlue}>
                <Queue />
            </Avatar>
        ),
        deploy_success: (
            <Avatar className={classes.avatarGreen}>
                <CloudDone />
            </Avatar>
        ),
        deploy_active: (
            <Avatar className={classes.avatarGreen}>
                <CloudDone />
            </Avatar>
        ),
        deploy_failed: (
            <Avatar className={classes.avatarRed}>
                <CloudOff />
            </Avatar>
        ),
        deploy_running: (
            <Avatar className={classes.avatarBlue}>
                <CloudUpload />
            </Avatar>
        ),
        deploy_provisioning: (
            <Avatar className={classes.avatarBlue}>
                <CloudQueue />
            </Avatar>
        )
    };

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
            disablePadding
        >
            {notifications.map((notification, i) => (
                <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider={i < notifications.length - 1}
                    key={notification.notificationId}
                    to={
                        notification.status == 'running' ? (notification.type == 'deploy' ? `/deploy/create/${notification.refId}` : `/build/create/${notification.refId}`)
                            : (notification.type == 'deploy' ? `/deploy/list/${notification.refId}` : `/build/list/${notification.refId}`)
                    }
                >
                    <ListItemAvatar>{avatars[notification.type + '_' + notification.status]}</ListItemAvatar>
                    <ListItemText
                        primary={notification.title + ' (' + notification.status + ')'}
                        primaryTypographyProps={{ variant: 'body1' }}
                        secondary={
                            notification.type + ' - ' + moment(notification.createdAt).fromNow()
                        }
                    />
                    <ArrowForwardIcon className={classes.arrowForwardIcon} />
                </ListItem>
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired
};

export default NotificationList;
