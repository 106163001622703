
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Divider, InputBase, Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    margin: {
        marginBottom: theme.spacing(2)
    },
    titleConfigure: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#263238'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    textArea : {
        // input: {
        borderRadius: 5,
        marginLeft: theme.spacing(2),
        marginTop: '5px',
        // fontStyle: 'italic',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '400px',
        padding: '10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderColor: theme.palette.primary.main
        }        
    }
}));

const ConfigurePublicKey = props => {
    const { idx, onChangeValue, ...rest } = props;
    const classes = useStyles();
    return (
        <>
            <Grid item xs={6}>
                <TextField
                    id="standard-multiline-flexible"
                    className={classes.textArea}
                    label=""
                    multiline
                    rows="10"
                    // col
                    rowsMax="40"
                    onChange={e => {
                        onChangeValue(e)
                    }}
                />
            </Grid>
            <Divider className={classes.divider} />
        </>
    );
};

ConfigurePublicKey.propTypes = {
    className: PropTypes.string
};

export default ConfigurePublicKey;
