import React from 'react';

class BuildDropdown extends React.Component {
    render() {
        const parsed = this.props.meta.length > 0 ? JSON.parse(this.props.meta) : null
        if (parsed) {
            // const x = parsed[this.props.key]
            return (
                <>
                    <span><strong>{this.props.runtime}</strong></span>
                    <br />
                    <span><strong>{parsed.hash}</strong></span>
                    <br />
                    <span>{parsed.message}</span>
                </>
            );
        }
        return (
            <>
                <span><strong>-</strong></span>
            </>
        );
    }
}

export default BuildDropdown;