import { Avatar, Button, Card, CardActions, CardContent, Divider, Grid, Table, TableBody, TableCell, TablePagination, TableRow, Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HomeIcon from '@material-ui/icons/Home';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SearchInput } from 'components';
import { getBuildDetail, getBuildList } from 'helpers/api';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import $ from 'jquery';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    content: {
        padding: 0
    },
    inner: {
        minWidth: 800
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    status: {
        marginRight: theme.spacing(1)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    txtMain: {
        fontSize: '12px',
        fontWeight: 'bold'
    },
    txtSub: {
        fontSize: '10px',
        color: '#90a4ae'
    },
    txtMainCards: {
        fontWeight: 'bold'
    },
    txtSubCards: {
        fontSize: '12px',
        color: '#90a4ae'
    },
    searchInput: {
        width: '50%',
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    btnNewBuild: {
        float: 'right',
        marginRight: theme.spacing(3)
    },
    txtBuild: {
        marginLeft: theme.spacing(1),
        textTransform: 'none'
    },
    btnRefreshBuild: {
        float: 'right',
        marginRight: theme.spacing(3),
        color: '#78909c',
    },
    headerCards: {
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(2)
    },
    containerGrid: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    cards: {
        paddingLeft: theme.spacing(2)
    },
    learnMore: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '5px'
    },
    iconFooter: {
        marginBottom: theme.spacing(1)
    },
    captPipeline: {
        fontSize: '10px',
        color: '#90a4ae'
    },
    titlePipeline: {
        fontWeight: 'bold'
    }
}));

const ListBuild = props => {

    const buildRunIcon = 'https://aku-io-static-assets.s3-ap-southeast-1.amazonaws.com/icon/Gear-0.2s-200px.gif'

    const { className, match } = props;
    // const buildId = match.params.buildId
    // console.log('buildId', buildId)

    const classes = useStyles();

    const [selectedBuildId, setSelectedBuildId] = useState(null)

    const [data, setData] = useState({ builds: [], meta: null });

    const [search, setSearch] = useState('')

    const [buildPage, setBuildPage] = useState(1)

    const [fetchState, setFetchState] = useState(false)

    useEffect(() => {
        // const buildId = match.params.buildId
        if (match.params.buildId !== null) {
            setSelectedBuildId(match.params.buildId)
        }
        fetchBuildList()
        const interval = setInterval(() => {
            console.log('This will run every 10 second!');
            fetchBuildList()
        }, 100000);

        // console.log('selectedBuildId', selectedBuildId)

        return () => clearInterval(interval);
    }, [])

    function handlePageChange(event, page) {
        fetchBuildList(page + 1)
    }

    function handleSearch(e) {
        // console.log('q', e.target.value)
        setSearch(e.target.value)
        fetchBuildList(buildPage)
    }

    function fetchBuildList(page = 1) {
        setFetchState(true)
        getBuildList(page, search).then(response => {
            const { data: builds, meta } = response.data;
            setData({
                builds: builds.map(b => Object.assign(b, { detail: null, expanded: false })),
                meta
            });
            setBuildPage(response.data.meta.current_page)
            setFetchState(false)
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            console.log('fetch data done')
            // Trigger click detail for selected build
            if (!!match.params.buildId){
                $('#btd-' + match.params.buildId).click()
            }
        })

    }

    async function expand(build, index) {
        console.log('expand.build', build)
        try {
            let detail = build.detail

            if (!build.expanded) {
                const getDetail = await getBuildDetail(build.buildId)

                const { data } = getDetail.data

                detail = data
            }

            const updated = Object.assign({}, data, {
                builds: data.builds.map((b, i) => {
                    if (i === index) {
                        return Object.assign({}, b, {
                            expanded: !b.expanded,
                            detail
                        })
                    }
                    return b
                })
            })

            setData(updated)

        } catch (error) {
            console.error(error)
        }
    }

    //   const [orders] = useState(mockData);
    function refreshList(event) {
        fetchBuildList()
    }

    return (
        <Card className={clsx(classes.root, className)}>
            <Paper elevation={0} className={classes.breadcrumbs}>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link
                        color="inherit"
                        to="/home"
                        className={classes.link}>
                        <HomeIcon className={classes.icon} />
                        Home
                  </Link>
                    <Typography color="textPrimary" className={classes.link}>
                        <ViewStreamIcon className={classes.icon} />
                        Build
                      </Typography> */}
                </Breadcrumbs>
            </Paper>
            <Grid container spacing={4}>
                <Grid item sm={6}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search build"
                        onChange={handleSearch}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Link to="/build/create" >
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.btnNewBuild}
                            style={{ marginRight: '5px' }}>
                            <AddIcon />
                            <span className={classes.txtBuild}>New Build</span>
                        </Button>
                    </Link>
                    <Button
                        onClick={refreshList}
                        // color="secondary"
                        variant="contained"
                        className={classes.btnRefreshBuild}
                        style={{ marginRight: '5px' }}>
                        <RefreshIcon />
                    </Button>
                </Grid>
            </Grid>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableBody>
                                {!fetchState && data.builds.length === 0 ? (
                                    <TableRow hover>
                                        <TableCell colSpan="6" align="center">
                                            No data available
                                    </TableCell>
                                    </TableRow>
                                ) : null}
                                {fetchState ? (
                                    <TableRow hover>
                                        <TableCell colSpan="6" align="center">
                                            Fetching data ...
                                    </TableCell>
                                    </TableRow>
                                ) : null}
                                {data.builds.map((build, i) =>
                                    <Fragment key={i}>
                                        <TableRow hover>
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Avatar
                                                        className={classes.avatar}
                                                        src={build.status === 'running' ? buildRunIcon : build.runtimeIcon}></Avatar>
                                                    <div>
                                                        <Typography className={classes.txtMain}>
                                                            {build.name}
                                                        </Typography>
                                                        <Typography className={classes.txtSub}>
                                                            by{' '}
                                                            <span
                                                                style={{ fontWeight: 'bold', color: '#37474f' }}>
                                                                {build.customer_name.length > 0 ? build.customer_name : build.customer_email}
                                                            </span>
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.txtMain}>{build.runtime}</Typography>
                                                <Typography className={classes.txtSub}>
                                                    Runtime
                    </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.txtMain}>{build.totalBuild}</Typography>
                                                <Typography className={classes.txtSub}>
                                                    Total Build
                    </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.txtMain}>
                                                    {moment(build.lastBuildDate).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                                </Typography>
                                                <Typography className={classes.txtSub}>
                                                    Last build date
                    </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.txtMain}>
                                                    {build.lastBuildStatus}
                                                </Typography>
                                                <Typography className={classes.txtSub}>
                                                    Last run status
                    </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.txtMain}>
                                                    {build.status || '-'}
                                                </Typography>
                                                <Typography className={classes.txtSub}>
                                                    Project status
                    </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {/* {(build.status !== 'running') && (
                                                    <Link to={"/build/create/" + build.buildId}>
                                                        <Button color="primary" variant="outlined" size="small">
                                                            RUN
                                                      </Button>
                                                    </Link>
                                                )} */}
                                                <Link to={"/build/create/" + build.buildId}>
                                                    <Button color="primary" variant="outlined" size="small">
                                                        {build.status !== 'running' ? 'RUN' : 'VIEW'}
                                                    </Button>
                                                </Link>
                                                {' '}
                                                <Button id={'btd-' + build.buildId} onClick={() => expand(build, i)} color="secondary" variant="outlined" size="small">
                                                    DETAIL
                        </Button>
                                            </TableCell>
                                        </TableRow>
                                        {(build.expanded && build.detail != null) && (
                                            <TableRow style={{ backgroundColor: '#eceff1' }}>
                                                <TableCell colSpan="7">
                                                    <Table style={{ backgroundColor: 'white' }}>
                                                        <TableBody>
                                                            {build.detail.map((detail, i) => (
                                                                <Fragment key={i}>
                                                                    <TableRow>
                                                                        <TableCell style={{ width: '3%' }}>
                                                                            <div className={classes.captPipeline}>
                                                                                <Typography className={classes.txtSub}>
                                                                                    No
                                                                        </Typography>
                                                                                #{detail.build_no}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ width: '30%' }}>
                                                                            {/* <Typography className={classes.txtSub}>
                                                                        Info
                                                                    </Typography> */}
                                                                            <div style={{ display: 'flex' }}>
                                                                                {/* <div className={classes.captPipeline}>
                                                                            <Typography className={classes.txtSub}>
                                                                                Build #no
                                                                            </Typography>
                                                                            {detail.build_no}
                                                                        </div> */}
                                                                                <div>
                                                                                    <Avatar
                                                                                        className={classes.avatar}
                                                                                        src={build.runtimeIcon}></Avatar>
                                                                                </div>
                                                                                <div>
                                                                                    <div className={classes.titlePipeline}>
                                                                                        {detail.git_commit_cmessage}
                                                                                    </div>
                                                                                    <div className={classes.captPipeline}>
                                                                                        {detail.git_commit_author}{' '}
                                                                                        <AccessTimeIcon
                                                                                            className={classes.captPipeline}
                                                                                            style={{ marginTop: '5px' }}
                                                                                        />{' '}
                                                                                        {detail.git_commit_hash}{' '}
                                                                                        <MergeTypeIcon
                                                                                            className={classes.captPipeline}
                                                                                        />{' '}
                                                                                        {detail.git_branch}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Typography className={classes.txtSub}>
                                                                                Status
                                                                    </Typography>
                                                                            {detail.build_status === 'success' ? (
                                                                                <div style={{ display: 'flex', color: '#2e7d32' }}>
                                                                                    <div style={{ marginRight: '4px' }}>
                                                                                        <CheckCircleIcon
                                                                                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                                        Successful
                                      </div>
                                                                                </div>
                                                                            ) : detail.build_status === 'failed' ? (
                                                                                <div style={{ display: 'flex', color: '#c62828' }}>
                                                                                    <div style={{ marginRight: '4px' }}>
                                                                                        <ErrorIcon
                                                                                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                                        Failed
                                      </div>
                                                                                </div>
                                                                            ) : ''}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography className={classes.txtSub}>
                                                                                Duration
                                                                    </Typography>
                                                                            {detail.build_duration}
                                                                        </TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                </Fragment>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Fragment>
                                )}
                                {/* ))} */}

                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
                {data.meta && (
                    <TablePagination
                        component="div"
                        count={data.meta.total}
                        onChangePage={handlePageChange}
                        // onChangeRowsPerPage={handleRowsPerPageChange}
                        page={data.meta.current_page - 1}
                        rowsPerPage={data.meta.size_per_page}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                )}
            </CardActions>

        </Card>
    );
};

ListBuild.propTypes = {
    className: PropTypes.string
};

export default ListBuild;
