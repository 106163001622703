import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  boxWelcome: {
    backgroundColor: theme.palette.boxGray,
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px'
  },
  btnSettings: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontSize: '12px'
  },
  subtitle: {
    paddingTop: theme.spacing(5)
  },
  clickHere: {
    textDecoration: 'underline',
    color: '#263238'
  }
}));

const BuildEmpty = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.boxWelcome}>
        <Typography variant="h4">No build configuration found</Typography>
        <Typography variant="h4">Let’s go ahead and create one!</Typography>
        <Typography className={classes.subtitle}>
          Please{' '}
          <a href="/" className={classes.clickHere}>
            click here
          </a>{' '}
          to learn about how to automate your build process.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.btnSettings}>
          Create New Build
        </Button>
      </div>
    </div>
  );
};

export default BuildEmpty;
