import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Card, Typography, Grid, Button, Avatar } from '@material-ui/core';
// import clsx from 'clsx';
import Delete from '@material-ui/icons/Delete';
// import Add from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Launch from '@material-ui/icons/Launch';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
    getAwsRegion,
    addCloudAccount,
    getCloudAccount,
    updateCloudAccount,
    deleteCloudAccount,
} from 'helpers/api';
import $ from 'jquery';
// import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(4),
        border: 'none',
    },
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    // paper: {
    //     top: 75,
    //     right: 0,
    //     left: 0,
    //     padding: theme.spacing(1),
    //     backgroundColor: theme.palette.background.paper,
    //     marginTop: theme.spacing(1)
    // },
    box: {
        border: 'none',
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: theme.spacing(1)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        padding: theme.spacing(2),
        border: 'none',
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1)
    },
    selectField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        // padding: theme.spacing(2),
        padding: '15px',
        border: 'none',
        backgroundColor: '#fff',
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1)
    },
    optionField: {
        // fontSize: '14px',
        // marginBottom: theme.spacing(1),
        // padding: theme.spacing(4),
        // display: 'block',
        backgroundColor: '#fff',
    },
    // txtBuild: {
    //     textTransform: 'none'
    // },
    // demo1: {
    //     backgroundColor: theme.palette.background.paper,
    // },
    // image: {
    //     width: '100%',
    //     height: '100%',
    // },
    // img: {
    //     margin: 'auto',
    //     display: 'block',
    //     maxWidth: '100%',
    //     maxHeight: '100%',
    // },
    formControlText: {
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '16px',
    },
}));

const AKUSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


// function DeleteDialog(props) {
//     const [openDialog, setOpenDialog] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpenDialog(true);
//     };

//     const handleCloseDialog = () => {
//         setOpenDialog(false);
//     };    
//     return (
//         <Dialog
//             open={openDialog}
//             onClose={handleCloseDialog}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//             {...props}
//         >
//             <DialogTitle id="alert-dialog-title">{"Delete?" + props.data.cloudAccountId}</DialogTitle>
//             <DialogContent>
//                 <DialogContentText id="alert-dialog-description">
//                     Let Google help apps determine location. This means sending anonymous location data to
//                     Google, even when no apps are running.
//           </DialogContentText>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleCloseDialog} color="primary">
//                     Disagree
//           </Button>
//                 <Button onClick={handleCloseDialog} color="primary" autoFocus>
//                     Agree
//           </Button>
//             </DialogActions>
//         </Dialog>

//     )
// }

const CloudAccounts = props => {

    const [cloudformationLink, setCloudformationLink] = React.useState(
        // "https://console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://s3-ap-southeast-1.amazonaws.com/resource.aku.io/aku_io-access.template.json&stackName=Aku-CrossAccount-Access&param_ExternalId=" + localStorage.getItem('x_uid')
        "https://console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://s3-ap-southeast-1.amazonaws.com/resource.aku.io/aku_io-access.template.json&stackName=Aku-CrossAccount-Access-" + Math.random().toString(36).substring(7) + "&param_ExternalId=" + localStorage.getItem('x_uid')
    );

    const classes = useStyles();

    const { className } = props;

    const theme = useTheme();

    const [didMount, setDidMount] = useState(false);

    const [region, setRegion] = React.useState([])

    const [cloudAccountData, setCloudAccountData] = React.useState([]);

    const form = React.createRef();

    const [state, setState] = React.useState({
        // checkedA: true,
        // checkedB: true,
        // checkedC: true,
    });

    // snackbar <flash message>
    const [processingOpen, setProcessingOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [flashMessage, setFlashMessage] = React.useState("");
    const [flashMessageError, setFlashMessageError] = React.useState("");
    const [error, setError] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const statusLabel = {
        "0": "Pending",
        "1": "Ready",
        "2": "Failed",
    }

    const resetChecked = (checked) => {
        cloudAccountData.map((val, index) => {
            const checkboxName = 'checked_' + index
            console.log(checkboxName)
            console.log(state)
            setState({ ...state, [checkboxName]: checked });
        })
    }

    const handleSwitchChange = name => event => {
        // const CLOUD_ID = (name.split('_'))[0]
        const ARN_ID = (name.split('_'))[1]
        const ACTIVE = event.target.checked ? 1 : 0

        // const activeCount = cloudAccountData.filter(e => e.isActive === 1).length;
        // console.log('activeCount', activeCount)

        // if (activeCount <= 1) {
        //     setError(true)
        //     setFlashMessageError("You need at least one ARN active at a time.")
        //     setOpen(true)
        //     return
        // }
        // setOpen(false)
        // setError(false)

        let selectedARN = cloudAccountData.filter(e => {
            console.log('XX', e.cloudAccountId == ARN_ID)
            if (e.cloudAccountId == ARN_ID) {
                e.isActive = ACTIVE
            } else {
                e.isActive = !ACTIVE
            }
            return e
        })
        console.log(selectedARN)
        console.log("CLOUD_ID", ARN_ID, ACTIVE)
        setCloudAccountData(selectedARN)
        handleChangeStatus(ARN_ID, ACTIVE)
        setState({ ...state, [name]: event.target.checked });
    };


    const handleChangeStatus = async (id, isActive) => {
        setProcessingOpen(true)
        updateCloudAccount({
            cloudAccountId: id,
            isActive: isActive
        }).then(() => {
            setFlashMessage("Your ARN status has been updated.")
            setOpen(true)
            fetchData()
        }).catch(err => {
            console.log(err)
            setError(true)
            setFlashMessageError("Ups, something wrong while updating ARN status. Please try again.")
            setOpen(true)
        }).finally(() => {
            setProcessingOpen(false)
        })
    };



    const handleDelete = async (id, name) => {
        setProcessingOpen(true)
        deleteCloudAccount({
            cloudAccountId: id
        }).then(() => {
            setFlashMessage("Your crendential for:" + name + " has been deleted.")
            setOpen(true)
            fetchData()
        }).catch(err => {
            console.log(err)
            setError(true)
            setFlashMessageError("Ups, something wrong while deleting CloudFormation Stack. Please try again.")
            setOpen(true)
        }).finally(() => {
            setProcessingOpen(false)
        })
    };


    const loadAwsRegion = async () => {
        try {
            const { data: result } = await getAwsRegion();
            let temp = []
            temp = result.data.map(option => {
                console.log(option.RegionName)
                return option.RegionName
            })
            setRegion(temp);
        } catch (error) {
            console.trace(error)
        }
    }


    const fetchData = async () => {
        console.log('Fetching data...')
        const result = await getCloudAccount();
        const { data } = result.data
        console.log('data', data)
        setCloudAccountData(data)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };




    useEffect(() => {
        setDidMount(true);
        fetchData()
        loadAwsRegion()
        // loadArn()
        // setValue(1);
        return () => setDidMount(false);
    }, [])

    if (!didMount) {
        return null;
    }

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    function validate() {
        return form.current.reportValidity();
    }

    const onSaveHandler = async () => {
        const formValid = validate()
        // console.log('valid-form', formValid)
        if (formValid) {
            let data = {}
            const form = $('#form-iam')
            form.serializeArray().map(function (item) {
                if (data[item.name]) {
                    if (typeof (data[item.name]) === "string") {
                        data[item.name] = [data[item.name]];
                    }
                    data[item.name].push(item.value);
                } else {
                    data[item.name] = item.value;
                }
            });
            // console.log('data', data)
            setProcessingOpen(true)
            addCloudAccount(data)
                .then(() => {
                    setError(false)
                    setFlashMessage("CloudFormation stack ARN has been created.")
                    form[0].reset()
                    setOpen(true)
                    fetchData()
                })
                .catch(err => {
                    console.log(err)
                    setError(true)
                    setFlashMessageError("Ups, something wrong while saving your CloudFormation Stack. " + err.message)
                    setOpen(true)
                }).finally(() => {
                    setProcessingOpen(false)
                });
        }
    };


    return (

        <>

            <div id="snackbar">
                <Snackbar
                    open={processingOpen}
                    message="Processing, please wait..."
                />
                {error ? (
                    <Snackbar open={open} onClose={handleClose} autoHideDuration={3000} >
                        <Alert onClose={handleClose} severity="error">
                            {flashMessageError}
                        </Alert>
                    </Snackbar>
                ) : (
                        <Snackbar open={open} onClose={handleClose} autoHideDuration={3000} >
                            <Alert onClose={handleClose} severity="success">
                                {flashMessage}
                            </Alert>
                        </Snackbar>
                    )}

            </div>

            <div id="notice-cloud" style={{
                marginTop: theme.spacing(3),
                padding: theme.spacing(3),
                backgroundColor: '#F7F3F3',
                borderRadius: '5px',
            }}>
                <div
                    style={{
                        color: theme.palette.red,
                        fontWeight: 'bold',
                        paddingBottom: theme.spacing(1)
                    }}>
                    Important
                        </div>
                <div style={{ fontSize: '14px' }}>
                    Choose the cloud provider below and follow the specific instructions carefully on how to link your cloud provider account(s). <br />
                    You must link <u>at least one</u> cloud account so that we can provision the resources on your infrastructure and deploy your builds automatically on your behalf
                        </div>
            </div>


            <div id="cloud-provider" style={{
                marginTop: theme.spacing(3),
            }}>
                Cloud providers
                    <div className={classes.separator2}></div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{
                        textTransform: 'none',
                        width: '30%',
                        display: 'block'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <div style={{ display: 'flex' }}>
                            <Avatar
                                src="/images/aws.png"
                                style={{ marginTop: '6px' }}
                            />
                            <div className={classes.selectContainer}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            paddingTop: theme.spacing(1),
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2)
                                        }}>
                                        Amazon Web Services
                                </div>
                                </div>
                                <div className={classes.selectCapt}></div>
                            </div>
                        </div>
                        <ExpandMoreIcon style={{ marginTop: '10px' }} />
                    </div>
                </Button>
            </div>

            <Paper className={classes.paper} style={{
                marginTop: theme.spacing(1),
                padding: theme.spacing(3),
                backgroundColor: '#F7F3F3',
                borderRadius: '5px',
                fontFamily: 'roboto',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '11pt',
                lineHeight: '25px',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                        <p>
                            Here is how you create a link your AWS account to aku.io: <br /><br />
                            <strong>Step 1</strong><br />
                            Click the following link to: <a style={{
                                //backgroundColor: theme.palette.red,
                                color: '#000',
                                fontWeight: 'bold',
                            }} href={cloudformationLink} target="_blank"><u>Launch your AWS console <Launch size={5} /></u></a>
                            <br />
                            You will be redirected to CloudFormation stack creation page to create a new ARN for aku.io
                                <br /><br />
                            <strong>Step 2</strong><br />
                            <span>Check <i>“I acknowledge that AWS CloudFormation might create IAM resources”</i> and click Create</span>
                            <br /><br />
                            <strong>Step 3</strong><br />
                            <span>Once the CloudFormation stack has been successfully created, go to the Outputs tab, copy the <i>AkuRoleARN</i> value and paste it below:</span>
                        </p>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        {/* <ButtonBase className={classes.image}> */}
                        <img width="100%" className={classes.img} alt="complex" src="https://aku-io-static-assets.s3-ap-southeast-1.amazonaws.com/icon/cloudformation.png" />
                        {/* </ButtonBase> */}
                    </Grid>
                    <Grid sm={12} xs={12}>

                        <form id="form-iam" ref={form} onSubmit={e => e.preventDefault()}>

                            <Grid container spacing={0}>
                                <input type="hidden" name="provider" value="aws" />
                                <Grid item sm={2} xs={12} p={0}>
                                    <label><strong>Default Region</strong></label><br />
                                    <select id="region-list" name="region" style={{
                                        width: '97%',
                                        marginLeft: 0,
                                    }} className={classes.selectField}>
                                        {region.map(option => (
                                            <option
                                                className={classes.optionField}
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <label><strong>Description (Optional)</strong></label><br />
                                    <input name="label" style={{
                                        width: '98%',
                                        marginLeft: 0,
                                    }} className={classes.textField} type="text" placeholder="e.g. Production AWS Account" required />
                                </Grid>
                                <Grid item sm={6} xs={12} p={0}>
                                    <label><strong>Paste the ARN here</strong></label><br />
                                    <input style={{
                                        // width: '98%',
                                        marginLeft: 0,
                                    }} name="value" className={classes.textField} type="text" placeholder="e.g. arn:aws:iam:5459892628:role/akuroleARN-IAMRole-4CA5F" required />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item sm={7} xs={12}></Grid>
                                <Grid item sm={4} xs={12} p={0} style={{
                                    textAlign: "right",
                                    paddingRight: '5px',
                                    // border : '1px solid #ccc'
                                }}>
                                    <Box component="span">
                                        Upon clicking save, we will attempt to verify the new ARN by establishing connection to your cloud account
                                            </Box>
                                </Grid>
                                <Grid item sm={1} xs={12} p={0} style={{
                                    textAlign: "right",
                                    // border: '1px solid #ccc'
                                }}>
                                    <Box component="span">
                                        <Button onClick={onSaveHandler} style={{
                                            marginTop: theme.spacing(1),
                                            width: '100%'
                                        }} variant="contained" color="primary">
                                            Save
                                                </Button>
                                    </Box>
                                </Grid>
                            </Grid>


                        </form>


                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid sm container style={{
                        marginTop: theme.spacing(1),
                        color: '#000'
                    }}>

                        <Grid item sm={12} xs={12}>
                            <Box component="strong" p={0}>
                                Your ARNs
                                    </Box>
                        </Grid>
                        <Grid item xs={12} sm={1} xs={12} container>
                            <Box component="span" p={0}>Status</Box>
                        </Grid>
                        <Grid item xs={12} sm={2} xs={12} container>
                            <Box component="span" p={0}>Region</Box>
                        </Grid>
                        <Grid item xs={12} sm={3} xs={12} container>
                            <Box component="span" p={0}>Description</Box>
                        </Grid>
                        <Grid item xs={12} sm={4} xs={12} container style={{
                            // border:'1px solid',
                        }}>
                            <Box component="span" p={0}>ARN</Box>
                        </Grid>
                        <Grid item xs={12} sm={2} xs={12} container style={{
                            // border: '1px solid',
                            // textAlign: "right",
                            fontStyle: 'italic',
                        }}
                            justify="flex-end"
                            alignItems="right"
                        >
                            <Box component="span" p={0}>Only one ARN can be active at a time</Box>
                        </Grid>

                    </Grid>


                    {cloudAccountData.map((val, index) =>
                        <Grid key={index} container style={{
                            marginLeft: 0,
                            padding: 0,
                        }} >
                            <Grid container spacing={0} style={{
                                marginBottom: theme.spacing(1)
                            }}>


                                <Grid item sm={1} xs={12}>
                                    <input readOnly name="status" style={{
                                        marginLeft: 0,
                                        width: '100%',
                                        border: 'none',
                                    }} className={classes.textField}
                                        type="text"
                                        value={statusLabel[val.status]}
                                    />
                                </Grid>

                                <Grid item sm={2} xs={12}>
                                    <input readOnly name="region" style={{
                                        marginLeft: 0,
                                        width: '100%',
                                        border: 'none',
                                    }} className={classes.textField}
                                        type="text"
                                        value={val.region}
                                    />
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <input readOnly name="desc" style={{
                                        marginLeft: 0,
                                        width: '100%',
                                        // border: '1px solid',
                                        border: 'none',
                                    }} className={classes.textField}
                                        type="text"
                                        value={val.label}
                                        placeholder="e.g. Production AWS Account" />
                                </Grid>
                                <Grid item sm={5} xs={12} p={0}>
                                    <input readOnly name="AwsIAM" style={{
                                        width: '100%',
                                        marginLeft: 0,
                                        border: 'none',
                                    }}
                                        className={classes.textField}
                                        type="text"
                                        value={val.value}
                                    />
                                </Grid>
                                <Grid item sm={1} xs={12} p={0} style={{
                                    textAlign: "right",
                                    backgroundColor: '#fff',
                                    // border:'1px solid'
                                }}>
                                    {val.status == 1 ?
                                        (
                                            <>
                                                <AKUSwitch
                                                    checked={val.isActive == 1}
                                                    onChange={handleSwitchChange('checked_' + val.cloudAccountId)}
                                                    value={"checked_" + val.cloudAccountId}
                                                />
                                                <ButtonBase
                                                    onClick={() => {
                                                        handleDelete(val.cloudAccountId, val.value)
                                                    }}
                                                >
                                                    <Delete style={{
                                                        width: 30,
                                                        height: 30,
                                                        // position:'relative',
                                                        // marginTop : '10px',
                                                    }} />
                                                </ButtonBase>
                                            </>
                                        ) : null}

                                </Grid>
                            </Grid>

                        </Grid>

                    )}


                </Grid>
            </Paper>

        </>
    );
};

export default CloudAccounts;
