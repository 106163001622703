// import React from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Card, Typography, Grid, Button, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import Paper from '@material-ui/core/Paper';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Link from '@material-ui/core/Link';
// import SettingsIcon from '@material-ui/icons/Settings';
// import Launch from '@material-ui/icons/Launch';
// import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import Delete from '@material-ui/icons/Delete';

// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
// import SwipeableViews from 'react-swipeable-views';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import FormControl from '@material-ui/core/FormControl';
// import InputBase from '@material-ui/core/InputBase';
// import InputLabel from '@material-ui/core/InputLabel';
import ButtonBase from '@material-ui/core/ButtonBase';
// import TextField from '@material-ui/core/TextField';
import { getAwsRegion, getAwsCredential, removeAwsCredential, saveAwsCredential } from 'helpers/api';
import $ from 'jquery';

import {
    CloudAccounts,
    Notifications
} from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    // breadcrumbs: {
    //     padding: theme.spacing(2, 2),
    //     // border: '1px solid #90a4ae',
    //     // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    //     margin: theme.spacing(3)
    //     // marginBottom: theme.spacing(2)
    // },
    // paper: {
    //     top: 75,
    //     right: 0,
    //     left: 0,
    //     padding: theme.spacing(1),
    //     backgroundColor: theme.palette.background.paper,
    //     marginTop: theme.spacing(1)
    // },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: theme.spacing(1)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        padding: theme.spacing(2),
        border: 'none',
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1)
    },
    txtBuild: {
        textTransform: 'none'
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    image: {
        width: '100%',
        height: '100%',
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const statusColors = {
    delivered: 'success',
    pending: 'info',
    refunded: 'danger'
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    // return (
    //     <Typography
    //         component="div"
    //         role="tabpanel"
    //         hidden={value !== index}
    //         id={`simple-tabpanel-${index}`}
    //         aria-labelledby={`simple-tab-${index}`}
    //         {...other}
    //     >
    //         {value === index && <Box p={3}>{children}</Box>}
    //     </Typography>
    // );
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Settings = props => {

    const classes = useStyles();

    const { className } = props;

    const theme = useTheme();

    const [didMount, setDidMount] = useState(false);

    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setDidMount(true);
        // loadArn()
        // setValue(1);
        return () => setDidMount(false);
    }, [])

    if (!didMount) {
        return null;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card className={clsx(classes.root)}>

            {/* <div className={classes.demo1} style={{
                //marginTop: theme.spacing(4),
            }}> */}
            <Grid className={classes.demo1}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        borderBottom: '1px solid #ccc'
                    }}
                    value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Cloud Accounts" {...a11yProps(0)} />
                    <Tab label="Notifications" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <CloudAccounts />

                </TabPanel>



                <TabPanel value={value} index={1}>
                    <Notifications />
                </TabPanel>

            </Grid>

            {/* </div> */}


        </Card>
    );
};

export default Settings;
