import {
    Avatar, Button, Grid, Menu, MenuItem, Select, TextField,
    FormControlLabel, Checkbox, FormControl, InputLabel, Input, FormHelperText,
    Table, TableContainer, TableRow, Paper, TableHead, TableBody, TableCell
} from '@material-ui/core';
import { Build as BuildIcon, Close as CloseIcon, Create as CreateIcon, Save as SaveIcon, Delete as DeleteIcon } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme } from '@material-ui/styles';
import { getBuildRuntime, getBuildRuntimeDetail } from 'helpers/api';
import React, { useEffect, useState } from 'react';
import TabPanel from './TabPanel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-terminal";
import produce from 'immer';


const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '14px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: "#000",
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    iconConfigure: {
        fontSize: '16px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    },
    selectRuntimeContainer: {
        width: '100%'
    },
    noUnderline: {
        "backgroundColor": "#fff",
        "&::before": {
            display: "none",
            height: 0
        },
        "&:after": {
            display: "none"
        }
    },
    table: {
        width: "100%",
        "& thead th": {
            padding: "10px"
        },
        "& tbody td": {
            backgroundColor: "#fff",
            padding: "10px",
            marginBottom: "5px"
        }
    }
}));

const Configure = props => {
    const { onClickNext, onClickPrev } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [runtimes, setRuntimes] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRuntimeIndex, setSelectedRuntimeIndex] = useState(null);
    const [runtimeDetail, setRuntimeDetail] = useState(null)
    const [editVersion, setEditVersion] = useState(false)
    const [editPm, setEditPm] = useState(false)
    const [editTest, setEditTest] = useState(false)
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [selectedPm, setSelectedPm] = useState(null)
    const [selectedTest, setSelectedTest] = useState(null)
    const [selectedTrigger, setSelectedTrigger] = useState(false)
    const [dialogInput, setDialogInput] = useState('')
    const [prebuildCommands, setPrebuildCommands] = useState([])
    const [postbuildCommands, setPostbuildCommands] = useState([])
    const [isInsertPrebuild, setIsInsertPrebuild] = useState(false)
    const [isInsertPostbuild, setIsInsertPostbuild] = useState(false)
    const [envars, setEnvars] = useState([]);

    const [envarInput, setEnvarInput] = useState({
        key: '',
        value: ''
    })

    const deleteEnvars = index => {
        setEnvars(produce(items => {
            items.splice(index, 1);
        }));
    }

    const handleRuntimeClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadDetail = async (id) => {
        try {
            const { data: result } = await getBuildRuntimeDetail(id)

            setRuntimeDetail(result.data)
            setSelectedVersion(0)
            setSelectedPm(0)
            setSelectedTest(0)
        } catch (error) {
            console.trace(error)
        }
    }

    const selectRuntime = index => {
        setSelectedRuntimeIndex(() => index)
        setAnchorEl(null)

        loadDetail(runtimes[index].id);
    }

    const loadRuntimes = async () => {
        try {
            const { data: result } = await getBuildRuntime()

            setRuntimes(() => result.data)
        } catch (error) {
            console.error(error)
        }
    }

    const dialogOnClose = () => {
        setIsInsertPostbuild(null)
        setIsInsertPrebuild(false)
        setDialogInput('')
    }

    const dialogOnSave = () => {
        const commands = dialogInput.split('\n');
        if (isInsertPrebuild) {
            setPrebuildCommands(commands)
        } else if (isInsertPostbuild) {
            setPostbuildCommands(commands)
        }

        dialogOnClose()
    }

    const toggleEditVersion = () => setEditVersion(v => !v)
    const toggleEditPm = () => setEditPm(v => !v)
    const toggleEditTest = () => setEditTest(v => !v)

    const handleVersionChange = e => {
        setSelectedVersion(e.target.value)
        setEditVersion(false)
    }
    const handlePmChange = e => {
        setSelectedPm(e.target.value)
        setEditPm(false)
    }
    const handleTestChange = e => {
        setEditTest(false)
        setSelectedTest(e.target.value)
    }

    const handleCheck = e => {
        // console.log(e.target.checked)
        // console.log(selectedTrigger)
        setSelectedTrigger(e.target.checked)
        // console.log(selectedTrigger)
        // this.setState({ value: e.target.value });
    }

    useEffect(() => {
        loadRuntimes()
    }, [])

    const selectedRuntime = selectedRuntimeIndex != null ? runtimes[selectedRuntimeIndex] : null
    const version = selectedVersion != null ? runtimeDetail.version[selectedVersion] : null
    const test = selectedTest != null ? runtimeDetail.testingFramework[selectedTest] : null
    const packageManager = selectedPm != null ? runtimeDetail.packageManager[selectedPm] : null

    const btnNextDisabled = selectedRuntime == null || selectedVersion == null;

    const setInputKey = value => {
        setEnvarInput(produce(e => {
            e.key = value
        }))
    }

    const setInputValue = value => {
        setEnvarInput(produce(e => {
            e.value = value;
        }))
    }

    const addEnvarInput = () => {
        setEnvars(produce(vars => {
            vars.push({
                key: envarInput.key,
                value: envarInput.value
            });
        }))
        setEnvarInput(produce(x => {
            x.key = '';
            x.value = '';
        }))
    }

    return (
        <TabPanel {...props}>
            <Dialog
                open={isInsertPrebuild || isInsertPostbuild}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Set
                    {' '}
                    {isInsertPrebuild ? 'Pre-build' : 'Post-build'}{' '}
                    Command
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <AceEditor
                            mode="sh"
                            theme="terminal"
                            value={dialogInput}
                            onChange={text => setDialogInput(text)}
                            name="UNIQUE_ID_OF_DIV"
                            editorProps={{ $blockScrolling: true }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dialogOnClose()} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => dialogOnSave()} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <div>
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: theme.spacing(1)
                    }}>
                    Would you like to
                </div>
                <Grid container spacing={4}>
                    <Grid item sm={6}>
                        <div style={{ marginBottom: theme.spacing(1) }}>
                            Use ready-made build templates
                        </div>
                        <div className={classes.separator}></div>
                        <div className={classes.selectRuntimeContainer}>
                            <Button style={{ width: '100%' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleRuntimeClick}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                    <div style={{ display: 'flex' }}>
                                        <Avatar
                                            src={selectedRuntime ? selectedRuntime.icon : ''}
                                            style={{ marginTop: '6px' }}
                                        />
                                        <div className={classes.selectContainer}>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                    {selectedRuntime ? selectedRuntime.name : 'Build Runtime'}
                                                </div>
                                            </div>
                                            <div className={classes.selectCapt}>
                                                {selectedRuntime ? selectedRuntime.description : 'Please select your build runtime.'}
                                            </div>
                                        </div>
                                    </div>
                                    <ExpandMoreIcon style={{ marginTop: '10px' }} />
                                </div>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {runtimes.map((runtime, index) => (
                                    <MenuItem key={runtime.id} onClick={() => selectRuntime(index)}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                            <div style={{ display: 'flex' }}>
                                                <Avatar
                                                    src={runtime.icon}
                                                    style={{ marginTop: '6px' }}
                                                />
                                                <div className={classes.selectContainer}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                            {runtime.name}
                                                        </div>
                                                    </div>
                                                    <div className={classes.selectCapt}>
                                                        {runtime.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <div style={{ display: 'flex', marginBottom: theme.spacing(1) }}>
                            Build my own
                            <div style={{ fontWeight: 'bold', fontSize: '10px', backgroundColor: '#e0e0e0', paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1), marginLeft: theme.spacing(1), borderRadius: '10px' }}>
                                Coming Soon
                            </div>
                        </div>
                        <div className={classes.separator}></div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: theme.spacing(2),
                                    marginLeft: theme.spacing(2),
                                    marginBottom: theme.spacing(2)
                                }}>
                                <BuildIcon style={{ marginTop: theme.spacing(2), color: 'rgb(158, 158, 158)' }} />
                                <div className={classes.selectContainer} style={{ color: 'rgb(158, 158, 158)' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                            Custom template
                                        </div>
                                    </div>
                                    <div style={{ fontSize: '11px', color: '#9e9e9e' }}>
                                        Build a general Node.js protect with npm.
                                    </div>
                                </div>
                            </div>
                            <ExpandMoreIcon style={{ marginTop: theme.spacing(4) }} />
                        </div>
                    </Grid>
                </Grid>
                {runtimeDetail ? (
                    <div>
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginTop: theme.spacing(3),
                                marginBottom: theme.spacing(2)
                            }}>
                            Settings
                        </div>
                        <div style={{ background: '#f5f5f5', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), borderRadius: '10px' }}>
                            <Grid container direction="column" spacing={5}>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Version</label>
                                        </Grid>
                                        <Grid item sm="3">
                                            <Select fullWidth variant="outlined"
                                                style={{ backgroundColor: "#fff" }}
                                                value={selectedVersion}
                                                onChange={handleVersionChange}>
                                                {runtimeDetail.version.map((v, i) => (
                                                    <MenuItem value={i}>{selectedRuntime.name} version {v}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Package Manager</label>
                                        </Grid>
                                        <Grid item sm="3">
                                            <Select
                                                style={{ backgroundColor: "#fff" }}
                                                fullWidth
                                                variant="outlined"
                                                value={selectedPm}
                                                onChange={handlePmChange}
                                            >
                                                {runtimeDetail.packageManager.map((pm, i) =>
                                                    <MenuItem value={i}>{pm}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Test Frameworks</label>
                                        </Grid>
                                        <Grid item sm="4">
                                            <Select fullWidth style={{ backgroundColor: '#fff' }} variant="outlined" value={selectedTest} onChange={handleTestChange}>
                                                {runtimeDetail.testingFramework.map((v, i) => (
                                                    <MenuItem value={i}>{v}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Custon pre-build commands</label>
                                        </Grid>
                                        <Grid item sm="6">
                                            <AceEditor
                                                width="100%"
                                                mode="sh"
                                                minLines={5}
                                                highlightActiveLine={false}
                                                maxLines={5}
                                                onChange={text => setPrebuildCommands(text.split('\n'))}
                                                theme="terminal"
                                                value={prebuildCommands.join('\n')}
                                                name="editor-prebuild"
                                                editorProps={{ $blockScrolling: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Custom post-build commands</label>
                                        </Grid>
                                        <Grid item sm="6">
                                            <AceEditor
                                                width="100%"
                                                mode="sh"
                                                onChange={text => setPostbuildCommands(text.split('\n'))}
                                                height="150"
                                                highlightActiveLine={false}
                                                minLines={5}
                                                maxLines={5}
                                                theme="terminal"
                                                value={postbuildCommands.join('\n')}
                                                name="editor-prebuild"
                                                editorProps={{ $blockScrolling: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="flex-start">
                                        <Grid item sm="4">
                                            <label>Environment Variables</label>
                                        </Grid>
                                        <Grid item sm="6">
                                            <table cellSpacing="0" cellPadding="0" className={classes.table} aria-label="simple table">
                                                <thead style={{ backgroundColor: 'transparent' }}>
                                                    <tr>
                                                        <th align="left">Key</th>
                                                        <th align="left">Value</th>
                                                        <th align="right"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {envars.map((row, index) => (
                                                        <>
                                                            <tr key={row.key} style={{ paddingBottom: 10, backgroundColor: "#fff" }}>
                                                                <td>
                                                                    {row.key}
                                                                </td>
                                                                <td>{row.value}</td>
                                                                <td align="right">
                                                                    <a onClick={() => deleteEnvars(index)} href="javascript:void(0)">
                                                                        <DeleteIcon className={classes.iconConfigure} />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr >
                                                                <td colSpan="3" style={{ backgroundColor: "transparent", padding: "3px" }}></td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                disableUnderline={true}
                                                                inputProps={{
                                                                    style: { padding: 0 }
                                                                }}
                                                                type="text"
                                                                value={envarInput.key}
                                                                onChange={e => setInputKey(e.target.value)}
                                                                placeholder="Enter Key" />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disableUnderline={true}
                                                                inputProps={{
                                                                    style: { padding: 0 }
                                                                }}
                                                                value={envarInput.value}
                                                                onChange={e => setInputValue(e.target.value)}
                                                                placeholder="Enter Value"
                                                            />
                                                        </td>
                                                        <td align="right">
                                                            <a onClick={() => addEnvarInput()} href="javascript:void(0)">
                                                                <AddIcon className={classes.iconConfigure} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item sm="4">
                                            <label>Triggers ?</label>
                                        </Grid>
                                        <Grid item sm="8">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        value="checkedB"
                                                        color="primary"
                                                        onChange={handleCheck}
                                                        checked={selectedTrigger}
                                                    />
                                                }
                                                label="Build Automatic"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                ) : null}
            </div>
            <div style={{ marginTop: theme.spacing(5) }}>
                <Grid
                    container
                    justify="space-between">
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.btnNext}
                        onClick={() => onClickPrev()}
                        style={{ marginRight: '5px' }}>
                        <NavigateBeforeIcon />
                        <span className={classes.txtNext}>Choose your build</span>
                    </Button>
                    <Button
                        disabled={btnNextDisabled}
                        color="primary"
                        variant="contained"
                        onClick={() => onClickNext(selectedRuntime, version, test, packageManager, prebuildCommands, postbuildCommands, selectedTrigger, envars)}
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Review</span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    )
}

export default Configure