import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SettingsIcon from '@material-ui/icons/SettingsRounded';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        // backgroundColor: theme.palette.error.main,
        backgroundColor: '#09b314',
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    }
}));

const BuildSuccess = props => {
    const { className, data, ...rest } = props;
    const classes = useStyles();
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            TOTAL SUCCESS BUILDS
            </Typography>
                        <Typography variant="h3">{data !== null ? data.totalSuccess : 0}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <SettingsIcon className={classes.icon} />
                        </Avatar>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};

BuildSuccess.propTypes = {
    className: PropTypes.string
};

export default BuildSuccess;
