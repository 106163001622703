import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Link,
    Typography
} from '@material-ui/core';
import GitHubLogin from 'react-github-login';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import { GitHub as GitHubIcon, Google as GoogleIcon } from 'icons';

import { signIn, signUpGoogle, signUpGithub } from 'helpers/api';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: '#333300',
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: '#1a1a00'
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    socialButtons: {
        marginTop: theme.spacing(3)
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    sugestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    loginFailed: {
        padding: theme.spacing(2, 2),
        marginTop: theme.spacing(1),
        color: '#721c24',
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
        fontFamily: 'Roboto',
        fontSize: '14px',
        borderRadius: '2px'
    }
}));

const SignIn = props => {
    const { history } = props;

    const btnGoogleSignin = useRef()
    const btnGithubSignin = useRef()

    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        showError: false
    });

    useEffect(() => {

        const gitHubBtnClick = btnGithubSignin.current.onBtnClick;

        btnGithubSignin.current.onBtnClick = (e) => {
            e.preventDefault()

            gitHubBtnClick()
        };

        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                const auth2 = window['gapi'].auth2.init({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email'
                });

                auth2.attachClickHandler(btnGoogleSignin.current, {}, (googleUser) => {
                    const profile = googleUser.getBasicProfile()

                    signUpGoogle(profile.getEmail(), profile.getId())
                        .then(response => response.data)
                        .then(result => result.data)
                        // .then(token => {
                        //     // localStorage.setItem('github_code', result.code)
                        //     localStorage.setItem('token', data.token)
                        //     localStorage.setItem('account_activated', data.account_activated)
                        //     localStorage.setItem('aws_authorized', data.aws_authorized)
                        //     localStorage.setItem('x_uid', data.x_uid)
                        // })
                        .then(data => {
                            // localStorage.setItem('github_code', result.code)
                            localStorage.setItem('token', data.token)
                            localStorage.setItem('account_activated', data.account_activated)
                            localStorage.setItem('aws_authorized', data.aws_authorized)
                            localStorage.setItem('x_uid', data.x_uid)
                            localStorage.setItem('o_email', data.email)
                            localStorage.setItem('github_code', data.github_authorized)
                        })
                        .then(() => history.push('/home'))
                });
            });
        }

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    }, [])

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleBack = () => {
        history.goBack();
    };

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = async (event) => {
        event.preventDefault();

        try {

            const response = await signIn(formState.values.email, formState.values.password)

            const { data } = response.data

            //   localStorage.setItem('token', data.token)
            localStorage.setItem('token', data.token)
            localStorage.setItem('account_activated', data.account_activated)
            localStorage.setItem('aws_authorized', data.aws_authorized)
            localStorage.setItem('x_uid', data.x_uid)
            localStorage.setItem('o_email', data.email)
            localStorage.setItem('github_code', data.github_authorized)

            history.push('/home')

        } catch (error) {
            setFormState(formState => ({
                ...formState,
                showError: true
            }))
        }
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const onGitHubSuccess = async (result) => {
        console.log('onGitHubSuccess', result)
        try {
            const response = await signUpGithub(result.code)

            const { data } = response.data;

            //console.log("data", data)

            localStorage.setItem('github_code', result.code)
            localStorage.setItem('token', data.token)
            localStorage.setItem('account_activated', data.account_activated)
            localStorage.setItem('aws_authorized', data.aws_authorized)
            localStorage.setItem('x_uid', data.x_uid)
            localStorage.setItem('o_email', data.email)

            history.push('/home')

        } catch (error) {
            setFormState(formState => ({
                ...formState,
                showError: true
            }))
        }

    }

    const onGitHubFailure = (error) => {
        console.log('failed', error)
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteContainer}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <Typography
                                className={classes.quoteText}
                                variant="h1"
                            >
                                DevOps-as-a-Service platform
              </Typography>
                            <div className={classes.person}>
                                <Typography
                                    className={classes.name}
                                    variant="body1"
                                >
                                    Build great products while we manage your software build and release processes ({process.env.REACT_APP_ID})
                </Typography>
                                <Typography
                                    className={classes.bio}
                                    variant="body2"
                                >
                                    Manager at inVision
                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={handleSignIn}
                            >
                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                >
                                    Sign in
                </Typography>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    Sign in with social media
                </Typography>
                                <Grid
                                    className={classes.socialButtons}
                                    container
                                    spacing={2}
                                >
                                    <Grid item>

                                        <GitHubLogin
                                            scope="user:email,repo,write:repo_hook"
                                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-sizeLarge"
                                            clientId={process.env.REACT_APP_GITHUB_LOGIN_CLIENTID}
                                            // clientId={"Iv1.b14f5a76908e9734"}
                                            onFailure={onGitHubFailure}
                                            onSuccess={onGitHubSuccess}
                                            // redirectUri="http://localhost:3000/sign-in"
                                            redirectUri={process.env.REACT_APP_GITHUB_LOGIN_REDIRECT}
                                            ref={btnGithubSignin}
                                        >
                                            <GitHubIcon className={classes.socialIcon} />
                                            Login with GitHub
                    </GitHubLogin>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            ref={btnGoogleSignin}
                                            size="large"
                                            variant="contained"
                                        >
                                            <GoogleIcon className={classes.socialIcon} />
                                            Login with Google
                    </Button>
                                    </Grid>
                                </Grid>
                                <Typography
                                    align="center"
                                    className={classes.sugestion}
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    or login with
                </Typography>
                                <TextField
                                    className={classes.textField}
                                    error={hasError('email')}
                                    fullWidth
                                    helperText={
                                        hasError('email') ? formState.errors.email[0] : null
                                    }
                                    label="Email address"
                                    name="email"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.email || ''}
                                    variant="outlined"
                                />
                                <TextField
                                    className={classes.textField}
                                    error={hasError('password')}
                                    fullWidth
                                    helperText={
                                        hasError('password') ? formState.errors.password[0] : null
                                    }
                                    label="Password"
                                    name="password"
                                    onChange={handleChange}
                                    type="password"
                                    value={formState.values.password || ''}
                                    variant="outlined"
                                />
                                {formState.showError && (
                                    <div className={classes.loginFailed}>
                                        Invalid Username or Password
                  </div>
                                )}
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign in now
                </Button>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Don't have an account?{' '}
                                    <Link
                                        component={RouterLink}
                                        to="/sign-up"
                                        variant="h6"
                                    >
                                        Sign up
                  </Link>
                                </Typography>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

export default withRouter(SignIn);
