
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Divider, InputBase, Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    margin: {
        marginBottom: theme.spacing(2)
    },
    titleConfigure: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#263238'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    textArea: {
        // input: {
        borderRadius: 5,
        marginLeft: theme.spacing(2),
        marginTop: '5px',
        // fontStyle: 'italic',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '400px',
        padding: '10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderColor: theme.palette.primary.main
        }
    }
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 5,
        marginLeft: theme.spacing(2),
        marginTop: '5px',
        // fontStyle: 'italic',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '100px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderColor: theme.palette.primary.main
        }
    }
}))(InputBase);

const ConfigureMinInstance = props => {
    const { defaultval, onChangeValue, ...rest } = props;
    const classes = useStyles();
    return (
        <>
            {/* <Grid item xs={2}> */}
                <BootstrapInput
                    type="number"
                    onChange={e => {
                        onChangeValue(e)
                    }}
                    // defaultValue={defaultval}
                value={defaultval}
                />

            {/* </Grid>             */}
            {/* <Divider className={classes.divider} /> */}
        </>
    );
};

ConfigureMinInstance.propTypes = {
    className: PropTypes.string
};

export default ConfigureMinInstance;
