import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Card, Typography, Grid, Button, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
    getContactList,
    saveContact,
    deleteContact,
    getNotificationPreference,
    updateNotificationPreference
} from 'helpers/api';
import $ from 'jquery';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(4),
        border: 'none',
    },
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    // paper: {
    //     top: 75,
    //     right: 0,
    //     left: 0,
    //     padding: theme.spacing(1),
    //     backgroundColor: theme.palette.background.paper,
    //     marginTop: theme.spacing(1)
    // },
    box: {
        border: 'none',
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: theme.spacing(1)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        padding: theme.spacing(2),
        border: 'none',
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1)
    },
    // txtBuild: {
    //     textTransform: 'none'
    // },
    // demo1: {
    //     backgroundColor: theme.palette.background.paper,
    // },
    // image: {
    //     width: '100%',
    //     height: '100%',
    // },
    // img: {
    //     margin: 'auto',
    //     display: 'block',
    //     maxWidth: '100%',
    //     maxHeight: '100%',
    // },
    formControlText: {
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '16px',
    },
}));

const Notifications = props => {
    // const [cloudformationLink, setCloudformationLink] = React.useState(
    //     // "https://console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://s3-ap-southeast-1.amazonaws.com/resource.aku.io/aku_io-access.template.json&stackName=Aku-CrossAccount-Access&param_ExternalId=" + localStorage.getItem('x_uid')
    //     "https://console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://s3-ap-southeast-1.amazonaws.com/resource.aku.io/aku_io-access.template.json&stackName=Aku-CrossAccount-Access-" + Math.random().toString(36).substring(7) + "&param_ExternalId=" + localStorage.getItem('x_uid')
    // );
    const progressIcon = 'https://aku-io-static-assets.s3-ap-southeast-1.amazonaws.com/icon/Gear-0.2s-200px.gif'

    const classes = useStyles();
    // snackbar <flash message>
    const [processingOpen, setProcessingOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [flashMessage, setFlashMessage] = React.useState("");

    const [state, setState] = React.useState({
        newPipeline: false,
        buildExecuted: false,
        deployExecuted: false,
    });

    const { newPipeline, buildExecuted, deployExecuted } = state;

    const error = [newPipeline, buildExecuted, deployExecuted].filter(v => v).length !== 2;

    // const { className } = props;
    const { className, match } = props;

    const theme = useTheme();

    const [didMount, setDidMount] = useState(false);

    const [value, setValue] = React.useState(0);

    const [tempContactId, setTempContactId] = React.useState(1000);
    const [newContactId, setNewContactId] = React.useState([]);

    const [emailData, setEmailData] = React.useState([]);

    const [deletedEmailData, setDeletedEmailData] = React.useState([]);

    const [formContactChanged, setFormContactChanged] = React.useState(false);

    const [formNotifChanged, setFormNotifChanged] = React.useState(false);


    const formContact = React.createRef();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // const loadDummyData = () => {
    //     let data = [{
    //         'contactId': '1',
    //         'label': 'Technical Team',
    //         'value': 'tech@gmail.com',
    //         'is_new': 0,
    //         'is_deleted': 0,
    //     }, {
    //         'contactId': '2',
    //         'label': 'Dev Account',
    //         'value': 'dev@gmail.com',
    //         'is_new': 0,
    //         'is_deleted': 0,
    //     }]
    //     setEmailData(data)
    // }

    const fetchNotifData = async () => {
        const result = await getNotificationPreference();
        const { data } = result.data
        console.log('fetchNotifData', data)
        if (data !== null) {
            setState({
                newPipeline: (data.NEW_PIPELINE == "on"),
                buildExecuted: (data.BUILD_EXECUTED == "on"),
                deployExecuted: (data.DEPLOY_EXECUTED == "on"),
            });
        } else {
            setState({
                newPipeline: false,
                buildExecuted: false,
                deployExecuted: false,
            });
        }
    }

    // const result = await getContactList()
    const fetchData = async () => {
        const result = await getContactList();
        const { data } = result.data
        setEmailData(data)
    };

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
        setFormNotifChanged(true)
    };


    useEffect(() => {
        setDidMount(true);
        // loadDummyData()

        fetchData()

        fetchNotifData()
        // .then(()=>{
        //     console.log('emailData', emailData)
        // });

        return () => setDidMount(false);
    }, [])

    if (!didMount) {
        return null;
    }

    function validate() {
        return formContact.current.reportValidity();
    }


    const onAddHandler = async () => {
        // const formValid = validate()
        // console.log('valid-form', formValid)
        if (formContact.current.reportValidity()) {
            const form = $('#form-contact')
            let data = {}
            form.serializeArray().map(function (item) {
                if (data[item.name]) {
                    if (typeof (data[item.name]) === "string") {
                        data[item.name] = [data[item.name]];
                    }
                    data[item.name].push(item.value);
                } else {
                    data[item.name] = item.value;
                }
            });
            console.log(data)
            const newTempContactId = tempContactId + 1
            data['contactId'] = newTempContactId
            data['is_new'] = 1
            data['is_deleted'] = 0
            setEmailData([...emailData, data])
            setTempContactId(newTempContactId)
            setNewContactId([...newContactId, newTempContactId])
            form[0].reset()
            setFormContactChanged(true)
        }
    };

    const onSaveHandler = async () => {
        console.log(deletedEmailData)
        console.log(newContactId)

        let deletedData = []
        deletedEmailData.forEach((val, i) => {
            if (!newContactId.includes(val)) {
                deletedData.push({
                    'contactId': val
                })
            }
        })
        console.log('deletedData', JSON.stringify(deletedData))

        let newData = []
        emailData.forEach((val, i) => {
            if (val.is_new == 1) {
                newData.push(val)
            }
        })
        console.log('newData', JSON.stringify(newData))

        setProcessingOpen(true)
        if (newData.length > 0) {
            saveContact(newData).then(() => {
                setFlashMessage("Contact list has been updated.")
                setOpen(true)


                // @todo : update is_new of new data in emailData, set it become 0
                // const newEmailData = newData.map((item, index) => {
                //     if (item.contactId == contactId) {
                //         item.is_deleted = 1
                //     }
                //     return item
                // })
                // setEmailData(newEmailData)



            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setProcessingOpen(false)
            });
        }

        if (deletedData.length > 0) {
            deleteContact(deletedData).then(() => {
                console.log('contact has been removed')
                if (newData.length == 0) {
                    setFlashMessage("Contact list has been updated.")
                    setOpen(true)
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setProcessingOpen(false)
            });
        }

    };


    const onDeleteHandler = async (id) => {
        const contactId = parseInt(id)
        setDeletedEmailData(deletedEmailData => [...deletedEmailData, contactId])
        const newEmailData = emailData.map((item, index) => {
            if (item.contactId == contactId) {
                item.is_deleted = 1
            }
            return item
        })
        setEmailData(newEmailData)
        setFormContactChanged(true)
    }

    const onNotifSaveHandler = async () => {
        const data = {
            "NEW_PIPELINE": state['newPipeline'] === true ? 'on' : 'off',
            "BUILD_EXECUTED": state['buildExecuted'] === true ? 'on' : 'off',
            "DEPLOY_EXECUTED": state['deployExecuted'] === true ? 'on' : 'off'
        }
        setProcessingOpen(true)
        updateNotificationPreference(data).then(() => {
            setFlashMessage("Notification preferences has been updated.")
            setOpen(true)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setProcessingOpen(false)
        });
    };

    return (

        <>

            <div id="snackbar">
                <Snackbar
                    open={processingOpen}
                    message="Processing, please wait..."
                />
                <Snackbar open={open} onClose={handleClose} autoHideDuration={3000} >
                    <Alert onClose={handleClose} severity="success">
                        {flashMessage}
                    </Alert>
                </Snackbar>
            </div>

            <Grid style={{
                marginTop: theme.spacing(3),
            }} >
                <Box component="div" style={{
                    padding: theme.spacing(3),
                    backgroundColor: '#F7F3F3',
                    borderRadius: '5px',
                }}>
                    <Box
                        component="span"
                        style={{
                            // color: theme.palette.red,
                            // fontWeight: 'bold',
                            paddingBottom: theme.spacing(1)
                        }}>
                        Notify via e-mail:
                </Box>
                    <Box style={{
                        paddingLeft: theme.spacing(4),
                    }}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={newPipeline} onChange={handleChange('newPipeline')} value="newPipeline" />}
                                    label="When a new build or deploy pipeline is created"
                                    className={classes.formControlText}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={buildExecuted} onChange={handleChange('buildExecuted')} value="buildExecuted" />}
                                    label={<Typography>When a build process is <u>executed</u></Typography>}
                                    className={classes.formControlText}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={deployExecuted} onChange={handleChange('deployExecuted')} value="deployExecuted" />
                                    }
                                    label={<Typography> When a deploy process is <u>executed</u> </Typography>}
                                    className={classes.formControlText}
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>

                    <Box component="div" style={{
                        marginTop: theme.spacing(2),
                        // border:'1px solid',
                    }}>
                        <Grid item xs={12} sm={12} container justify="flex-end">
                            <Grid item xs={12} sm={1} style={{
                                // border:'1px solid red',

                            }}>
                                <Box component="span">
                                    <Button
                                        disabled={formNotifChanged === false}
                                        onClick={onNotifSaveHandler}
                                        style={{
                                            marginTop: theme.spacing(1),
                                            width: '100%'
                                        }} variant="contained" color="primary">
                                        Save
                                </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                </Box>
            </Grid>


            <Grid container style={{
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(1),
            }}>
                <Box component="div">
                    <Typography style={{
                        fontWeight: 'bold'
                    }}>Notification Recipients&nbsp;<span style={{
                        fontWeight: 'normal',
                        fontStyle: 'italic',
                    }}><small>up to 5 additional recipients</small></span>
                    </Typography>
                </Box>
            </Grid>


            <Grid>
                <Box component="div" style={{
                    padding: theme.spacing(3),
                    backgroundColor: '#F7F3F3',
                    borderRadius: '5px',
                }}>
                    <Grid container spacing={0} style={{
                        marginBottom: theme.spacing(1)
                    }}>
                        <Grid item sm={4} xs={12}>
                            <Box
                                component="span"
                                style={{
                                    fontWeight: 'bold'
                                }}>
                                Description
                            </Box>
                        </Grid>
                        <Grid item sm={7} xs={12} p={0}>
                            <Box
                                component="span"
                                style={{
                                    fontWeight: 'bold'
                                }}>
                                E-mail address
                            </Box>
                        </Grid>
                        <Grid item sm={1} xs={12} p={0}></Grid>
                    </Grid>
                    <Grid>

                        <Grid>
                            <Grid container spacing={0} style={{
                                marginBottom: theme.spacing(1),
                            }}>
                                <Grid item sm={4} xs={12} style={{
                                    paddingLeft: theme.spacing(2)
                                }}>
                                    <Typography><u>Owner</u></Typography>
                                </Grid>
                                <Grid item sm={8} xs={12} style={{
                                    paddingLeft: theme.spacing(2)
                                }}>
                                    <Typography><u>{localStorage.getItem('o_email')}</u></Typography>
                                </Grid>
                            </Grid>
                        </Grid>


                        {emailData.map((val, index) =>
                            val.is_deleted == 0 ? (
                                <Grid key={index}>
                                    <Grid container spacing={0} style={{
                                        marginBottom: theme.spacing(1),
                                    }}>
                                        <Grid item sm={4} xs={12}>
                                            <input readOnly name="AwsAccountName" style={{
                                                width: '100%',
                                                border: 'none',
                                                marginLeft: 0,
                                            }} className={classes.textField}
                                                type="text"
                                                value={val.label}
                                            />
                                        </Grid>
                                        <Grid item sm={7} xs={12} p={0}>
                                            <input readOnly name="AwsIAM" style={{
                                                width: '100%',
                                                marginLeft: 0,
                                                border: 'none',
                                            }}
                                                className={classes.textField}
                                                type="text"
                                                value={val.value}
                                            />
                                        </Grid>
                                        <Grid item sm={1} xs={12} p={0} style={{
                                            textAlign: "right",
                                            backgroundColor: '#fff',
                                            // border:'1px solid'
                                        }}>
                                            <ButtonBase onClick={() => {
                                                // alert(val.contactId)
                                                onDeleteHandler(val.contactId)
                                            }}>
                                                <Delete style={{
                                                    width: 28,
                                                    height: 28,
                                                    position: 'relative',
                                                    top: '10px',
                                                    right: '10px',
                                                    // marginTop : '10px',
                                                }} />
                                            </ButtonBase>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            ) : null
                        )}

                        <>
                            <form id="form-contact" ref={formContact} onSubmit={e => e.preventDefault()}>
                                <Grid container spacing={0} style={{
                                    marginBottom: theme.spacing(1),
                                }}>
                                    <input type="hidden" name="isOwner" value="0" />
                                    <Grid item sm={4} xs={12}>
                                        <input name="label" style={{
                                            width: '100%',
                                            border: 'none',
                                            marginLeft: 0,
                                        }} className={classes.textField}
                                            type="text"
                                            placeholder="Enter description"
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={7} xs={12} p={0}>
                                        <input name="value" style={{
                                            width: '100%',
                                            marginLeft: 0,
                                            border: 'none',
                                        }}
                                            className={classes.textField}
                                            type="email"
                                            placeholder="Enter an email address"
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={1} xs={12} p={0} style={{
                                        textAlign: "right",
                                        backgroundColor: '#fff',
                                        // border:'1px solid'
                                    }}>
                                        <ButtonBase onClick={onAddHandler}>
                                            <Add style={{
                                                width: 28,
                                                height: 28,
                                                position: 'relative',
                                                top: '10px',
                                                right: '10px',
                                                // marginTop : '10px',
                                            }} />
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </form>
                        </>

                    </Grid>

                    <Box component="div" style={{
                        marginTop: theme.spacing(2),
                        // border:'1px solid',
                    }}>
                        <Grid xs={12} sm={12} container item justify="flex-end">
                            <Grid item xs={12} sm={1} style={{
                                // border:'1px solid red',

                            }}>
                                <Box component="span">
                                    <Button
                                        disabled={formContactChanged === false}
                                        onClick={onSaveHandler}
                                        style={{
                                            marginTop: theme.spacing(1),
                                            width: '100%'
                                        }} variant="contained" color="primary">
                                        Save
                                </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                </Box>
            </Grid>


        </>
    );
};

export default Notifications;
