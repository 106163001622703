import { Avatar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Select, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme } from '@material-ui/styles';
import { getBranchList, getRepoList, getProviders, githubAuthorize } from 'helpers/api';
import React, { useEffect, useState, useRef } from 'react';
import TabPanel from './TabPanel';
import GitHubLogin from 'react-github-login';
import { GitHub as GitHubIcon } from 'icons';

const useStyles = makeStyles(theme => ({
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '12px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    selectedRepo: {
        color: 'red'
    },
    btnPaginateRepo: {
        // float: 'right',
        // marginRight: theme.spacing(3),
        color: '#78909c',
    },
    loadRepoText : {
        marginLeft: theme.spacing(3),
    },    
}));

const ChooseBuild = props => {
    const { onClickNext } = props;
    const [anchorEl, setAnchorEl] = useState(null)
    const [repos, setRepos] = useState([])
    const [repoPage, setRepoPage] = useState(1)
    const [repoCanPaginateNext, setRepoCanPaginateNext] = useState(false)
    const [showLoadRepo, setShowLoadRepo] = useState(false)
    const [branches, setBranches] = useState([])
    const [selectedProvider, setSelectedProvider] = useState(null)
    const [selectedRepo, setSelectedRepo] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState('')
    const [providers, setProviders] = useState([])
    const [needAuth, setNeedAuth] = useState(false)
    const btnGithubSignin = useRef()
    const [openError, setOpenError] = useState(false)
    const selectBranchLabel = useRef()
    const [labelWidth, setLabelWidth] = React.useState(0);

    const classes = useStyles();
    const theme = useTheme();

    const handleRepoClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadProviders = async () => {
        try {
            const data = await getProviders();

            setProviders(data);
        } catch (error) {
            console.trace(error)
        }
    }

    const loadRepository = async (page) => {
        setShowLoadRepo(true)
        try {
            const response = await getRepoList(page)
            console.log('can next', response)
            setRepoCanPaginateNext(response.data.meta.more_page)
            setRepos(response.data.data)
        } catch (error) {
            console.error(error);
        }
        setShowLoadRepo(false)
    }

    const selectRepo = async (index) => {
        try {
            const repo = repos[index]

            const { data: result } = await getBranchList(repo.name)

            setBranches(() => result.data)
            setSelectedRepo(_ => index)

            setSelectedBranch(0)

            setLabelWidth(selectBranchLabel.current.offsetWidth);
        } catch (error) {
            setOpenError(true)
        }
    }

    const selectBranch = value => {
        console.log("value", value)
        setSelectedBranch(() => value)
    }

    const selectProvider = index => {
        setSelectedProvider(index)

        const provider = providers[index]

        if (provider.id === 'github') {
            const github_code = localStorage.getItem('github_code');

            if (github_code === null || github_code === undefined || github_code.length === 0) {
                setNeedAuth(true)
            } else {
                loadRepository(repoPage)
            }
        }

        setAnchorEl(null);
    }

    const onGitHubSuccess = async (result) => {
        try {
            await githubAuthorize(result.code);

            localStorage.setItem('github_code', result.code);

            setNeedAuth(false)

            loadRepository(repoPage)

        } catch (error) {
            // setFormState(formState => ({
            //     ...formState,
            //     showError: true
            // }))
            alert('failed: ' + error)
        }

    }

    const onGitHubFailure = () => {
        alert('failed authorize github')
    }

    const onRepoPagination = async (context) => {
        console.log(context)
        let page = repoPage
        if(context === 'prev'){
            page--
        } else {
            page++
        }
        setRepoPage(page)
        try {
            loadRepository(page)
        } catch (error) {
            // setFormState(formState => ({
            //     ...formState,
            //     showError: true
            // }))
            alert('failed: ' + error)
        }
    }


    useEffect(() => {
        loadProviders()
    }, [])

    const handleCloseError = () => {
        setOpenError(false)
    }

    const handleOk = () => {
        setOpenError(false)
    }

    const btnNextDisabled = selectedRepo == null || selectedBranch == null
    const provider = selectedProvider == null ? null : providers[selectedProvider];

    return (
        <TabPanel {...props}>
            <Dialog
                open={openError}
                onClose={handleCloseError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'Load Failed'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cannot load branches of this repository
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ marginBottom: theme.spacing(3) }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Where is your source code ?
                </span>
                <div className={classes.separator2}></div>

                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleRepoClick}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <div style={{ display: 'flex' }}>
                            <Avatar
                                src={provider ? provider.icon : ''}
                                style={{ marginTop: '6px' }}
                            />
                            <div className={classes.selectContainer}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                        {provider ? provider.name : 'Source Code Provider'}
                                    </div>
                                    {/* <div className={classes.badges}>YAML</div> */}
                                </div>
                                <div className={classes.selectCapt}>
                                    {provider ? provider.desc : 'Select provider where your source code hosted'}
                                </div>
                            </div>
                        </div>
                        <ExpandMoreIcon style={{ marginTop: '10px' }} />
                    </div>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {providers.map((provider, index) =>
                        <MenuItem onClick={() => selectProvider(index)} key={index} disabled={!provider.enabled}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <div style={{ display: 'flex' }}>
                                    <Avatar
                                        src={provider.icon}
                                        style={{ marginTop: '6px' }}
                                    />
                                    <div className={classes.selectContainer}>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                {provider.name}
                                            </div>
                                            <div className={classes.badges}>YAML</div>
                                        </div>
                                        <div className={classes.selectCapt}>
                                            {provider.desc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MenuItem>
                    )}
                </Menu>

                {needAuth ? (
                    <div style={{ marginTop: '10px' }}>
                        <GitHubLogin
                            scope="user:email,repo,write:repo_hook"
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-sizeLarge"
                            clientId={process.env.REACT_APP_GITHUB_AUTHORIZE_CLIENTID}
                            onFailure={onGitHubFailure}
                            onSuccess={onGitHubSuccess}
                            redirectUri={process.env.REACT_APP_GITHUB_AUTHORIZE_REDIRECT}
                            ref={btnGithubSignin}
                        >
                            <GitHubIcon className={classes.socialIcon} />
                            Authorize Github
                    </GitHubLogin>
                    </div>
                ) : null}
                {selectedProvider != null && !needAuth ? (
                    <div>
                        <div className={classes.wrapper}>
                            <div className={classes.paper}>
                                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                    Which repository ?{' '}
                                </span>
                                <span style={{ fontSize: '11px' }}>
                                    Your repositories will be listed below once you connect
                                    to your repo provider
                                </span>


                                <List>
                                    {repos.map((repo, index) => (
                                        <ListItem key={index} button selected={selectedRepo === index} onClick={() => selectRepo(index)}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    src="/images/github.png"
                                                    style={{ marginTop: '6px' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText style={{ paddingLeft: '15px' }}>
                                                <div
                                                    style={{ fontWeight: 'bold', paddingTop: '10px' }}>
                                                    {repo.name}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: '11px',
                                                        color: '#9e9e9e',
                                                        fontWeight: 'bold'
                                                    }}>
                                                    11 Sep
                                                </div>
                                            </ListItemText>
                                            {selectedRepo === index ? (
                                                <ListItemSecondaryAction style={{ marginTop: theme.spacing(1) }}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel ref={selectBranchLabel} id="select-branch-outlined-label">
                                                            Branch
                                                        </InputLabel>
                                                        <Select
                                                            labelId="select-branch-outlined-label"
                                                            id="select-branch-outlined"
                                                            onChange={(e) => selectBranch(e.target.value)}
                                                            labelWidth={labelWidth}
                                                            value={selectedBranch}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {branches.map((branch, index) => (
                                                                <MenuItem value={index}>{branch.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            ) : null}

                                        </ListItem>
                                    ))}
                                </List>
                                <Grid item>
                                    <Button
                                        disabled={repoPage === 1}
                                        size="small"
                                        variant="contained"
                                        className={classes.btnPaginateRepo}
                                        onClick={() => onRepoPagination('prev')}
                                    >
                                        &laquo;

                                    </Button>
                                    &nbsp;
                                    <Button
                                        disabled={!repoCanPaginateNext}
                                        size="small"
                                        variant="contained"
                                        className={classes.btnPaginateRepo}
                                        onClick={() => onRepoPagination('next')}
                                    >
                                        &raquo;

                                    </Button>
                                    {
                                        showLoadRepo ?
                                            <Typography variant="caption" className={classes.loadRepoText}>
                                                Load repositories...
                                            </Typography> : null
                                    }
                                </Grid>                                
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div style={{ marginTop: theme.spacing(5) }}>
                <Grid
                    container
                    justify="flex-end">
                    <Button
                        onClick={() => onClickNext(repos[selectedRepo], branches[selectedBranch])}
                        color="primary"
                        variant="contained"
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Configure</span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    )
}

export default ChooseBuild