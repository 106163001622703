import { Avatar, Button, Grid, Menu, MenuItem, Select, Divider, Checkbox, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import BuildIcon from '@material-ui/icons/Build';
// import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import PlusOne from '@material-ui/icons/PlusOne';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TabPanel from './TabPanel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AceEditor from "react-ace";
import ConfigurePublicKey from './ConfigurePublicKey';
import ConfigureStackEnv from './ConfigureStackEnv';
import ConfigureEnvVar from './ConfigureEnvVar';
import ConfigureMinInstance from './ConfigureMinInstance';
import ConfigureMaxInstance from './ConfigureMaxInstance';

import { getCloudProviders, getDeployEnvironment, getDeployServices, getDeployServiceOs, getDeployInstanceType, getDeployStorageType } from 'helpers/api';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '14px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: '#000',
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));

function valuetext(value) {
    return `${value}°C`;
}

const PrettoSlider = withStyles({
    root: {
        color: '#848385',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 5,
        borderRadius: 4,
    },
    rail: {
        height: 5,
        borderRadius: 4,
    },
})(Slider);



const Configure = props => {
    // const { selectedBuild, onNext, deployId, onClickPrev } = props;
    const { onNext, deployId, onClickPrev } = props;

    // //for testing
    // const { onNext, deployId, onClickPrev } = props;
    let selectedBuild = {
        build: {
            // runtime: "php"
            runtime: "golang"
        }
    }

    // console.log(deployId)
    //const { data, onNext, deployId, onClickPrev} = props;

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [providers, setProviders] = useState([]);
    const [selectedProviderIndex, setSelectedProviderIndex] = useState(null)
    const [environments, setEnvironments] = useState([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [envMenu, setEnvMenu] = useState(null)
    const [editEnvironment, setEditEnvironment] = useState(null)
    const [editStorageType, setEditStorageType] = useState(false)
    const [selectedDeploymentStrategy, setSelectedDeploymentStrategy] = useState(null)
    const [editDeploymentStrategy, setEditDeploymentStrategy] = useState(null)
    // const [deploymentStrategy, setDeploymentStrategy] = useState([]);
    const [instanceSize, setInstanceSize] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    //services
    const [services, setServices] = useState([]);
    const [serviceMenu, setServiceMenu] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    // os
    const [serviceOSes, setServiceOSes] = useState([]);
    const [serviceOSmenu, setServiceOSMenu] = useState(null);
    const [selectedServiceOS, setSelectedServiceOS] = useState(0);
    const [editOS, setEditOS] = useState(false)

    // instance type
    const [instances, setInstances] = useState([]);
    const [instanceMenu, setInstanceMenu] = useState(null);
    const [selectedInstance, setSelectedInstance] = useState(0)
    const [editType, setEditType] = useState(false)

    // region
    const [editRegion, setEditRegion] = useState(false)
    const [selectedRegion, setSelectedRegion] = useState(0)
    const [region, setRegion] = useState(null);

    // for slider
    const [instanceValue, setInstanceValue] = React.useState([1, 5]);

    const loadInstances = async (provider) => {
        try {
            const { data: result } = await getDeployInstanceType(provider.id)

            setInstances(result.data)
        } catch (error) {
            console.error(error);
        }
    }

    // storage type
    const [storages, setStorages] = useState([]);
    const [storageMenu, setStorageMenu] = useState(null);
    const [selectedStorage, setSelectedStorage] = useState(0)
    const [editStorageSize, setEditStorageSize] = useState(false)

    const selectStorage = index => {
        setSelectedStorage(index);
        setStorageMenu(null)
    }

    const loadStorages = async (provider) => {
        try {
            const { data: result } = await getDeployStorageType(provider.id);

            setStorages(result.data);
        } catch (error) {
            console.error(error)
        }
    }

    const selectInstance = index => {
        setSelectedInstance(index)
        setInstanceMenu(null)
    }

    // storage sizes
    const [storageSizes, setStorageSizes] = useState([
        20,
        40,
        60,
        80,
        100
    ]);
    const [storageSizeMenu, setStorageSizeMenu] = useState(null);
    const [selectedStorageSize, setSelectedStorageSize] = useState(0);

    const selectStorageSize = index => {
        setSelectedStorageSize(index)
        setStorageSizeMenu(null)
    }


    // editor post & pre build cmd
    const [dialogInput, setDialogInput] = useState('')
    // const [prebuildCommands, setPrebuildCommands] = useState([])
    // const [postbuildCommands, setPostbuildCommands] = useState([])
    const [prebuildCommands, setPrebuildCommands] = useState('')
    const [postbuildCommands, setPostbuildCommands] = useState('')
    const [isInsertPrebuild, setIsInsertPrebuild] = useState(false)
    const [isInsertPostbuild, setIsInsertPostbuild] = useState(false)
    const [enableSSH, setEnableSSH] = useState(false)
    const [disableReviewBtn, setDisableReviewBtn] = useState(true)

    // public key
    const [publicKey, setPublicKey] = useState('')

    const [minInstance, setMinInstance] = useState(0)
    const [maxInstance, setMaxInstance] = useState(0)

    // service stack env
    const [stackEnv] = useState({
        'golang': [
            {
                'required': 'true',
                'type': 'number',
                'label': 'Expossed Port',
                'hint': 'Application Port. Port 8080 is reserved!',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            },
            {
                'required': 'false',
                'type': 'string',
                'label': 'Static Dir',
                'hint': 'Static directory. Separate by comma (,) if has multiple dir',
                'key': 'TF_VAR_static_dir',
                'value': ''
            },
            {
                'required': 'true',
                'type': 'string',
                'label': 'Main Script',
                'hint': 'Application main script. Defaut main.go',
                'key': 'TF_VAR_main_script',
                'value': 'main.go'
            }
        ],
        'react': [],
        'nodejs': [
            {
                'required': 'true',
                'type': 'number',
                'label': 'Expossed Port',
                'hint': 'Application Port. Port 8080 is reserved!',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            }
        ],
        'php': [
            {
                'required': 'true',
                'type': 'string',
                'label': 'Document Root',
                'hint': 'Public directory that contains entry point script',
                'key': 'TF_VAR_document_root',
                'value': ''
            }
        ],
        'python': [
            {
                'required': 'true',
                'type': 'string',
                'label': 'WSGI Script',
                'hint': 'Wsgi script',
                'key': 'TF_VAR_WSGI_path',
                'value': 'main.py'
            },
            {
                'required': 'false',
                'type': 'string',
                'label': 'Static Dir',
                'hint': 'Static directory. Separate by comma (,) if has multiple dir',
                'key': 'TF_VAR_static_dir',
                'value': ''
            }
        ],
    })

    // ecs service stack env
    const [ecsStackEnv] = useState({
        'golang': [
            {
                'label': 'Expossed Port',
                'hint': 'Application Port',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            }
        ],
        'nodejs': [
            {
                'label': 'Expossed Port',
                'hint': 'Application Port',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            }
        ],
        'php': [
            {
                'label': 'Expossed Port',
                'hint': 'Application Port',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            }
        ],
        'python': [
            {
                'label': 'Expossed Port',
                'hint': 'Application Port',
                'key': 'TF_VAR_exposed_port',
                'value': '9000'
            }
        ],
    })

    const [serviceDeploymentStrategy] = useState({
        'ecs': ['inplace', 'bluegreen'],
        'ec2': ['inplace'],
        'elasticbeanstalk': ['bluegreen'],
    })

    const [awsRegion] = useState([
        {
            "id": "US East (Ohio)",
            "name": "us-east-2"
        },
        {
            "id": "US East (N. Virginia)",
            "name": "us-east-1"
        },
        {
            "id": "US West (N. California)",
            "name": "us-west-1"
        },
        {
            "id": "US West (Oregon)",
            "name": "us-west-2"
        },
        {
            "id": "Asia Pacific (Hong Kong)",
            "name": "ap-east-1"
        },
        {
            "id": "Asia Pacific (Mumbai)",
            "name": "ap-south-1"
        },
        {
            "id": "Asia Pacific (Osaka-Local)",
            "name": "ap-northeast-3"
        },
        {
            "id": "Asia Pacific (Seoul)",
            "name": "ap-northeast-2"
        },
        {
            "id": "Asia Pacific (Singapore)",
            "name": "ap-southeast-1"
        },
        {
            "id": "Asia Pacific (Sydney)",
            "name": "ap-southeast-2"
        },
        {
            "id": "Asia Pacific (Tokyo)",
            "name": "ap-northeast-1"
        },
        {
            "id": "Canada (Central)",
            "name": "ca-central-1"
        },
        {
            "id": "Europe (Frankfurt)",
            "name": "eu-central-1"
        },
        {
            "id": "Europe (Ireland)",
            "name": "eu-west-1"
        },
        {
            "id": "Europe (London)",
            "name": "eu-west-2"
        },
        {
            "id": "Europe (Paris)",
            "name": "eu-west-3"
        },
        {
            "id": "Europe (Stockholm)",
            "name": "eu-north-1"
        },
        {
            "id": "Middle East (Bahrain)",
            "name": "me-south-1"
        },
        {
            "id": "South America (São Paulo)",
            "name": "sa-east-1"
        }
    ])


    // env variables
    const [environmentVariable, setEnvironmentVariable] = useState([1, 2, 3, 4, 5]);


    const [deploymentStrategy, setDeploymentStrategy] = useState([])

    const [serviceEnv, setServiceEnv] = useState([])

    const [userEnv, setUserEnv] = useState([])

    const [appEnv, setAppEnv] = useState([])

    const [appEnvValid, setAppEnvValid] = useState([])

    const handleChangeSliderInstance = (event, newValue) => {
        console.log('instanceValue', newValue, newValue[0], newValue[1])
        if (newValue[0] < newValue[1]){
            setInstanceValue(newValue);
            setMinInstance(newValue[0]);
            setMaxInstance(newValue[1]);
        } else {
            alert("Min Instance cannot be greater than Max Instance")
            // setInstanceValue([1,2]);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };


    const selectEnvironment = index => {
        setSelectedEnvironment(index)
        setEnvMenu(null)
    }

    const openProviderMenu = (event) => {
        setAnchorEl(event.target)
    }

    const openEnvironmentMenu = event => {
        setEnvMenu(event.target)
    }

    const openServiceMenu = event => {
        setServiceMenu(event.target);
    }

    const openServiceOSMenu = event => {
        setServiceOSMenu(event.target);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dialogOnClose = () => {
        setIsInsertPostbuild(null)
        setIsInsertPrebuild(false)
        setDialogInput('')
    }

    const dialogOnSave = () => {
        // const commands = dialogInput.split('\n');
        // if (isInsertPrebuild) {
        //     setPrebuildCommands(commands)
        // } else if (isInsertPostbuild) {
        //     setPostbuildCommands(commands)
        // }
        const commands = dialogInput;
        if (isInsertPrebuild) {
            setPrebuildCommands(commands)
        } else if (isInsertPostbuild) {
            setPostbuildCommands(commands)
        }
        dialogOnClose()
    }



    const onChangePublicKey = (e) => {
        // console.log('key', e.target.value)
        setPublicKey(e.target.value)
    }

    const onChangeMinInstance = (e) => {
        let val = parseInt(e.target.value)
        if (val < 1) {
            val = 1
        }
        // console.log('min-instance', val)
        setMinInstance(val)
        // console.log('new-min-instance', minInstance)
    }

    const onChangeMaxInstance = (e) => {
        // console.log('min-instance', minInstance)
        let val = parseInt(e.target.value)
        if (val < 1) {
            val = 1
        }
        setMaxInstance(val)
    }


    const onChangeStackEnv = (e) => {
        // console.log('appEnv.before', appEnv)
        // console.log('onChangeStackEnv', e.target.name)
        const getIndex = e.target.name.split('_')
        appEnv[getIndex[1]] = {
            'label': getIndex[2],
            'key': e.target.id,
            'value': e.target.value
        }
        setAppEnv(appEnv)
        // console.log('appEnv.after', appEnv)
        // console.log('appEnv.length', appEnv.length)
    }

    const onBlurStackEnv = (e) => {
        console.log('onBLURStackEnv', e.target.name)
        console.log('onBLURStackEnv.required', e.target.required)
        console.log('onBLURStackEnv.value', e.target.value)
        console.log('onBLURStackEnv.appEnvValid-1', appEnvValid)
        const getIndex = e.target.name.split('_')
        let x = appEnvValid
        if (e.target.required) {
            if (e.target.value == "") {
                x[getIndex[1]] = 0
                setAppEnvValid(x)
                document.getElementById(e.target.id).style.border = "1px solid red"
            } else {
                x[getIndex[1]] = 1
                setAppEnvValid(x)
                document.getElementById(e.target.id).style.border = "1px solid #ced4da"
            }
        } else {
            x[getIndex[1]] = 1
            setAppEnvValid(x)
            document.getElementById(e.target.id).style.border = "1px solid #ced4da"
        }
        // console.log('onBLURStackEnv.appEnvValid-2', appEnvValid)
        setDisableReviewBtn(appEnvValid.includes(0))
    }

    const onChangeUserEnv = (e) => {
        const identity = e.target.id.split('-')
        const name = identity[0]
        const index = identity[1]
        // console.log('userEnv-Before', userEnv)
        if (name === 'envkey') {
            const dataValue = document.getElementById('envval-' + index)
            const env = {
                'key': e.target.value,
                'value': dataValue.value
            }
            if (e.target.value !== '' && dataValue.value !== '') {
                userEnv[index] = env
                setUserEnv(userEnv)
            } else {
                userEnv.splice(index, 1);
                setUserEnv(userEnv)
            }
        }
        if (name === 'envval') {
            const dataValue = document.getElementById('envkey-' + index)
            const env = {
                'key': dataValue.value,
                'value': e.target.value
            }
            if (e.target.value !== '' && dataValue.value !== '') {
                userEnv[index] = env
                setUserEnv(userEnv)
            } else {
                userEnv.splice(index, 1);
                setUserEnv(userEnv)
            }

        }
        // console.log('userEnv-After', userEnv)
    }

    // function handleClick(event) {
    //     event.preventDefault();
    //     alert('You clicked a breadcrumb.');
    // }

    const loadCloudProviders = async () => {
        try {
            const { data: result } = await getCloudProviders();

            setProviders(result.data)
        } catch (error) {
            // console.error(error)
        }
    }

    const loadEnvironments = async () => {
        try {
            const { data: result } = await getDeployEnvironment();

            // console.log(result)

            setEnvironments(result.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        loadCloudProviders();
        loadEnvironments();
        // console.log("selectedBuild", selectedBuild)
    }, [])

    const loadServices = async (provider) => {
        try {
            const { data: result } = await getDeployServices(provider.id);
            // console.log(result);
            setServices(result.data)
        } catch (error) {
            console.error(error)
        }
    }

    const loadServiceOSes = async (service) => {
        try {
            const { data: result } = await getDeployServiceOs(service.id);

            setServiceOSes(result.data)
        } catch (error) {
            console.error(error);
        }
    }

    const selectProvider = index => {
        setAnchorEl(null)
        // console.log('the-provider', providers[index])
        if (providers[index].id === "aws") {
            setSelectedProviderIndex(index)
            loadServices(providers[index])
            loadInstances(providers[index])
            loadStorages(providers[index])
        } else {
            setSelectedProviderIndex(null)
        }
    }

    const selectService = index => {
        setSelectedService(index)
        setServiceMenu(null)
        if (services[index].id === "ec2") {
            const useStackEnv = stackEnv[selectedBuild.build.runtime]
            console.log('useStackEnv', useStackEnv, useStackEnv.length)
            setServiceEnv(useStackEnv)
            useStackEnv.map((data, index) => {
                appEnv[index] = {
                    'label': data.label,
                    'key': data.key,
                    'value': data.value
                }
                setAppEnv(appEnv)
            })
            // console.log('appEnv.default', appEnv)
            if (useStackEnv.length === 0) {
                setDisableReviewBtn(false)
            }
        }
        if (services[index].id === "elasticbeanstalk") {
            const useStackEnv = stackEnv[selectedBuild.build.runtime]
            setServiceEnv(useStackEnv)
            useStackEnv.map((data, index) => {
                appEnv[index] = {
                    'label': data.label,
                    'key': data.key,
                    'value': data.value
                }
                setAppEnv(appEnv)
            })
            if (useStackEnv.length === 0) {
                setDisableReviewBtn(false)
            }
        }
        if (services[index].id == "ecs") {
            const useStackEnv = ecsStackEnv[selectedBuild.build.runtime]
            setServiceEnv(useStackEnv)
            useStackEnv.map((data, index) => {
                appEnv[index] = {
                    'label': data.label,
                    'key': data.key,
                    'value': data.value
                }
                setAppEnv(appEnv)
            })
            if (useStackEnv.length === 0) {
                setDisableReviewBtn(false)
            }
        }
        // 
        //loadServiceOSes(services[index])
        const getDeploymentStrategy = serviceDeploymentStrategy[services[index].id]
        // console.log('getDeploymentStrategy', getDeploymentStrategy)
        setDeploymentStrategy(serviceDeploymentStrategy[services[index].id])
        setSelectedDeploymentStrategy(getDeploymentStrategy[0])
        setSelectedRegion(8)
        setRegion(awsRegion[8].id + ' - ' + awsRegion[8].name)

    }

    const selectOs = index => {
        setSelectedServiceOS(index)
        setServiceOSMenu(null)
    }

    const closeServiceOsMenu = () => {
        setServiceOSMenu(null)
    }

    // const cloudProvider = selectedProviderIndex != null ? providers[selectedProviderIndex] : null;
    // const environment = selectedEnvironment == null ? null : environments[selectedEnvironment];
    // const service = selectedService == null ? null : services[selectedService];
    // const serviceOS = selectedServiceOS == null ? null : serviceOSes[selectedServiceOS];
    // const instance = selectedInstance == null ? null : instances[selectedInstance];
    // const storage = selectedStorage == null ? null : storages[selectedStorage];
    // const storageSize = selectedStorageSize == null ? null : storageSizes[selectedStorageSize];

    const cloudProvider = 'aws';
    const environment = 'development';
    const service = 'elasticbeanstalk';
    const serviceOS = '';
    const instance = 't2.nano';
    const storage = '';
    const storageSize = '';


    return (
        <TabPanel {...props}>

            {/* ace editor */}

            <Dialog
                open={isInsertPrebuild || isInsertPostbuild}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Set
                    {' '}
                    {isInsertPrebuild ? 'Pre-build' : 'Post-build'}{' '}
                    Command
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <AceEditor
                            mode="sh"
                            theme="terminal"
                            value={dialogInput}
                            onChange={text => setDialogInput(text)}
                            name="UNIQUE_ID_OF_DIV"
                            editorProps={{ $blockScrolling: true }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dialogOnClose()} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => dialogOnSave()} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>


            {/* !-ace editor */}

            <div>
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: theme.spacing(1)
                    }}>
                    What would you like to
                        </div>
                <Grid container spacing={4}>
                    <Grid item sm={6}>
                        <div>
                            <div style={{ marginBottom: theme.spacing(1) }}>Deploy to</div>
                            <div className={classes.separator}></div>
                            <Button
                                onClick={openProviderMenu}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: theme.spacing(2),
                                        marginLeft: theme.spacing(2),
                                        marginBottom: theme.spacing(2)
                                    }}>
                                    {cloudProvider === null ?
                                        <BuildIcon className={classes.iconBuild} /> :
                                        <Avatar
                                            src={cloudProvider.icon}
                                            style={{ marginTop: theme.spacing(1) }}
                                        />
                                    }
                                    <div className={classes.selectContainer}>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                {cloudProvider == null ? 'Choose Hosting Provider' : cloudProvider.name}
                                            </div>
                                        </div>
                                        <div style={{ fontSize: '11px', color: '#9e9e9e' }}>
                                            {cloudProvider == null ? 'Host your builds to your favorite provider' : cloudProvider.description}
                                        </div>
                                    </div>
                                </div>
                                <ExpandMoreIcon style={{ marginTop: theme.spacing(4) }} />
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {providers.map((provider, index) =>
                                    <MenuItem onClick={() => selectProvider(index)} key={index}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                            <div style={{ display: 'flex' }}>
                                                <Avatar
                                                    src={provider.icon}
                                                    style={{ marginTop: '6px' }}
                                                />
                                                <div className={classes.selectContainer}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                            {provider.name}
                                                        </div>
                                                    </div>
                                                    <div className={classes.selectCapt}>
                                                        {provider.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </MenuItem>
                                )}
                            </Menu>
                        </div>

                        {selectedProviderIndex != null ? (
                            <div>
                                <div style={{ marginBottom: theme.spacing(1) }}>
                                    Resource Type
                            </div>
                                <div className={classes.separator}></div>
                                <Button
                                    onClick={openServiceMenu}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: theme.spacing(2),
                                            marginLeft: theme.spacing(2),
                                            marginBottom: theme.spacing(2)
                                        }}>

                                        {service == null ? (
                                            <BuildIcon className={classes.iconBuild} />
                                        ) : (
                                                <Avatar
                                                    src={service.icon}
                                                    style={{ marginTop: theme.spacing(1) }}
                                                />
                                            )}
                                        <div className={classes.selectContainer}>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                    {service == null ? 'Select Service' : service.name}
                                                </div>
                                            </div>
                                            <div style={{ fontSize: '11px', color: '#9e9e9e' }}>
                                                {service == null ? 'Select your favorite service' : service.description}
                                            </div>
                                        </div>
                                    </div>
                                    <ExpandMoreIcon style={{ marginTop: theme.spacing(4) }} />
                                </Button>
                                <Menu
                                    anchorEl={serviceMenu}
                                    keepMounted
                                    open={Boolean(serviceMenu)}
                                >
                                    {services.map((service, index) =>
                                        <MenuItem onClick={() => selectService(index)} key={index}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}>
                                                <div style={{ display: 'flex' }}>
                                                    <Avatar
                                                        src={service.icon}
                                                        style={{ marginTop: '6px' }}
                                                    />
                                                    <div className={classes.selectContainer}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div
                                                                style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                                {service.name}
                                                            </div>
                                                        </div>
                                                        <div className={classes.selectCapt}>
                                                            {service.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        ) : null}

                    </Grid>
                    <Grid item sm={6}>
                        <div>
                            <div
                                style={{ display: 'flex', marginBottom: theme.spacing(1) }}>
                                Build my own
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '10px',
                                        backgroundColor: '#e0e0e0',
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                        borderRadius: '10px'
                                    }}>
                                    Coming Soon
                                    </div>
                            </div>
                            <div className={classes.separator}></div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: theme.spacing(2),
                                        marginLeft: theme.spacing(2),
                                        marginBottom: theme.spacing(2)
                                    }}>
                                    <BuildIcon
                                        style={{
                                            marginTop: theme.spacing(2),
                                            color: 'rgb(158, 158, 158)'
                                        }}
                                    />
                                    <div
                                        className={classes.selectContainer}
                                        style={{ color: 'rgb(158, 158, 158)' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                                                Custom template
                                            </div>
                                        </div>
                                        <div style={{ fontSize: '11px', color: '#9e9e9e' }}>
                                            Build a general Node.js protect with npm.
                                            </div>
                                    </div>
                                </div>
                                <ExpandMoreIcon style={{ marginTop: theme.spacing(4) }} />
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {cloudProvider != null && service != null ? (
                    // <a>A</a>
                    <Grid container item lg="12" md="12" xs="12">


                        <Grid container item lg="12" md="12" xs="12" style={{
                            border: '1px solid',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginTop: theme.spacing(3),
                            marginBottom: theme.spacing(2)
                        }}>
                            Configuration details
                        </Grid>

                        <Grid container item lg="12" md="12" xs="12" style={{
                            // border: '1px solid',
                            background: '#f5f5f5',
                            // paddingLeft: theme.spacing(2),
                            // paddingRight: theme.spacing(2),
                            padding: theme.spacing(2),
                            borderRadius: '2px'
                            // fontSize: '16px',
                            // fontWeight: 'bold',
                            // marginTop: theme.spacing(3),
                            // marginBottom: theme.spacing(2)
                        }}>

                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    // border: '1px solid',
                                }}>
                                    <Typography component="label">Number of instances</Typography>
                                    {/* <Typography variant="caption" >
                                        caption text
                                    </Typography>  */}
                                    {/* <Typography component="span">Number of instances</Typography> */}
                                    {/* minInstance */}
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    // border: '1px solid',
                                }}>
                                    <Grid container item lg="3" md="3" xs="12">
                                        <PrettoSlider
                                            min={1}
                                            max={10}
                                            value={instanceValue}
                                            onChange={handleChangeSliderInstance}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                            getAriaValueText={valuetext}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Application port</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>


                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Deployment strategy</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>



                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Enable SSH access ?</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>


                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Custom pre-build commands</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>


                            <Grid container item lg="12" md="12" xs="12"  style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Custom post-build commands</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>


                            <Grid container item lg="12" md="12" xs="12" style={{
                                marginBottom: theme.spacing(2)
                            }}>
                                <Grid container item lg="3" md="3" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">Environment variables</Typography>
                                </Grid>
                                <Grid container item lg="9" md="9" xs="12" style={{
                                    border: '1px solid',
                                }}>
                                    <Typography component="label">xxx</Typography>
                                </Grid>
                            </Grid>


                        </Grid>




                    </Grid>

                ) : null}
            </div>
            <div style={{ marginTop: theme.spacing(5) }}>
                <Grid
                    container
                    justify="space-between">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onClickPrev()}
                        className={classes.btnNext}
                        style={{ marginRight: '5px' }}>
                        <NavigateBeforeIcon />
                        <span className={classes.txtNext}>Prev</span>
                    </Button>
                    <Button
                        onClick={() => onNext({
                            cloudProvider,
                            environment,
                            service,
                            serviceOS,
                            instance,
                            storage,
                            storageSize,
                            userEnv,
                            selectedDeploymentStrategy,
                            selectedRegion: awsRegion[selectedRegion].name,
                            prebuildCommands,
                            postbuildCommands,
                            publicKey,
                            applicationEnv: appEnv,
                            minInstance: minInstance,
                            maxInstance: maxInstance,
                        })}
                        color="primary"
                        variant="contained"
                        // disabled={[cloudProvider, environment, service, serviceOS, instance, storage, storageSize].some(x => x === null)}
                        disabled={[cloudProvider, environment, service, instance].some(x => x === null) || disableReviewBtn}
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Review</span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    );
};

Configure.propTypes = {
    className: PropTypes.string
};

export default Configure;
