import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import { runDeploy, checkDeployPhase } from 'helpers/api';
import mqtt from 'mqtt';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import TabPanel from './TabPanel';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '12px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: "#000",
        padding: theme.spacing(2),
        listStyleType: 'none',
        fontFamily: 'Inconsolata, monospace',
        fontSize: '11px',
    },
    messageItem: {
        fontWeight: 'bold',
        color: '#fff'
    },
    messageItemError: {
        fontWeight: 'bold',
        color: '#cc3300',
    },
    titleConfigure: {
        fontSize: '12px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '12px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    },
    buttonProgress: {
        position: 'absolute'
    }
}));

const Deploy = props => {
    const totalTopic = 6
    const { deployId, isRunning, deployScenario, onClickPrev } = props;
    // const { deployState, setDeployState} = useState(1)
    const classes = useStyles();
    const [currentTopic, setCurrentTopic] = useState(null)
    const [firstRun, setFirstrun] = useState(true);
    const [currentMessages, setCurrentMessages] = useState([])
    const [done, setDone] = useState([])
    const [error, setError] = useState(false)
    const [expanded, setExpanded] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [waitingMessage, setWaitingMessage] = useState(false);
    const [disabledFinish, setDisabledFinish] = useState(false);
    const [disabledPrev, setDisabledPrev] = useState(false);
    const [deployPhase, setDeployPhase] = useState(0);
    const [disabledDeployBtn, setDisabledDeployBtn] = useState(false);
    const [lastTopic, setLastTopic] = useState('');

    function getMessagesForTopic(messages, topic) {
        // console.log('getMessagesForTopic', topic, messages)
        const m = messages.filter(message => message[0] === topic).map(message => message[1])
        // console.log('msg', m)
        // return messages.filter(message => message[0] === topic).map(message => message[1]);
        return m
    }

    function getTopicIndex(topic) {
        // deploytopic4:0b7aac76-9632-43a5-bb12-dc0b9f933b42
        return topic.split(':')[0]
    }

    function mqttRUN(phase = null) {
        // setDone([])

        // const totalTopic = 6

        let topics = []
        // generate topic
        if (phase === null || phase < 1) {
            for (let i = 1; i <= totalTopic; i++) {
                topics.push('deploytopic' + i + ':' + deployId)
            }
        } else {
            for (let i = phase; i <= totalTopic; i++) {
                topics.push('deploytopic' + i + ':' + deployId)
            }
        }
        console.log(topics)

        // const topics = [
        //     'deploytopic1:' + deployId,
        //     'deploytopic2:' + deployId,
        //     'deploytopic3:' + deployId,
        //     'deploytopic4:' + deployId,
        //     'deploytopic5:' + deployId,
        //     'deploytopic6:' + deployId,
        // ];

        const client = mqtt.connect(process.env.REACT_APP_MQTT_HOST, {
            // clientId: 'clientID-' + parseInt(Math.random() * 100),
            // port: 8083,
            // username: 'nssakuioU19',
            // password: 'x266!nssakuioU!9',
            clientId: `clientID-${deployId}-${parseInt(Math.random() * 100)}`,
            port: process.env.REACT_APP_MQTT_PORT,
            username: process.env.REACT_APP_MQTT_USER,
            password: process.env.REACT_APP_MQTT_PASS,
            clean: false, //set to false to receive QoS 1 and 2 messages while offline
            // queueQoSZero: if connection is broken, queue outgoing QoS zero messages(default true)
        });

        client.on('connect', () => {
            // console.log('subscribing to deploytopic1')
            // console.log(topics)
            client.subscribe(topics, { qos: 2 })
        });

        client.on('message', (topic, msg) => {
            const data = JSON.parse(msg.toString());

            // console.log(data)
            // console.log(setCurrentMessages, messages => messages.concat([[topic, data.Content]]))

            setWaitingMessage(false);
            setCurrentTopic(data.ID);
            setCurrentMessages(messages => messages.concat([[topic, data.Content]]));

            // console.log('currentTopic', data.ID)
            console.log('topic', topic)

            // // save latest topic & it's message on storage
            // // setLastTopic(topic)
            // const topicKey = 'last_topic_' + deployId
            // // const topicMsgKey = 'last_message_topic_' + deployId
            // const topicMsgKey = 'last_message_topic_' + data.ID
            // let lastMsgTopic = localStorage.getItem(topicMsgKey)
            // if (lastMsgTopic !== null) {
            //     lastMsgTopic = lastMsgTopic.concat('+', data.Content)
            //     localStorage.setItem(topicMsgKey, lastMsgTopic)
            // } else {
            //     localStorage.setItem(topicMsgKey, '')
            // }
            // localStorage.setItem(topicKey, data.ID)

            // console.log('status', data.Status)
            // console.log('DONE', done)
            // console.log('done.indexOf(topic)', done.indexOf(topic))

            if (data.Status == 1 && done.indexOf(topic) == -1) {
                // console.log('done.indexOf(topic)', done.indexOf(topic))
                setDone(done => done.concat([topic]))
                client.unsubscribe(topic, () => {
                    console.log('topic', topic, 'has been unsubscribed')
                })
                // clear

                // console.log('getTopicIndex', getTopicIndex(topic))
                if (getTopicIndex(topic) == 'deploytopic6') {
                    client.end(true);
                    setDisabledFinish(false)
                    setProcessing(false)
                }
            }

            if (data.Status == 2) {
                // setCurrentMessages(messages => messages.concat([[topic, "Error !!!"]]));
                setCurrentMessages(messages => messages.concat([[topic, "Deployment Failed."]]));
                setError(true)
                setProcessing(false)
                client.end(true);
            }
        })

        client.on('offline', () => {
            console.log('MQTT offline')
            setCurrentMessages(messages => messages.concat([[currentTopic, "You're offline, attemping to connect..."]]));
        });

        client.on('reconnect', () => {
            console.log('MQTT reconnect')
            setCurrentMessages(messages => messages.concat([[currentTopic, "re-connecting..."]]));
        });

        client.on('packetreceive', () => {
            console.log('MQTT packetreceive')
        });

        client.on('end', () => {
            console.log('MQTT connection ended.')
            console.log('LAST_TOPIC', localStorage.getItem('last_topic'))
            console.log('LAST_MSG_TOPIC', localStorage.getItem('last_message_topic'))
            // setCurrentMessages([]);
        });
    }

    function onReDeployHandler(deployState) {
        setDone(done => done.splice(0, done.length))
        console.log('DONE', done)
        setCurrentTopic(null)
        setCurrentMessages(currentMessages => currentMessages.splice(0, currentMessages.length))
        setProcessing(true)
        setWaitingMessage(true)
        setDisabledFinish(true)
        checkDeployPhase(deployId).then((rs) => {
            const { data: result } = rs
            console.log("phase", result)
            console.log("phase", result.data.phase)

            // const currentTopic = 'deploytopic1:' + result.data.phase
            // setCurrentTopic(currentTopic);
            // setCurrentMessages(messages => messages.concat([[currentTopic, result.data.message]]));

            mqttRUN(result.data.phase)
            if (!result.data.is_running) {
                runDeploy(deployId, deployState)
            }
        }).catch(err => {
            console.log("phase.ERROR", err)
        })
        // mqttRUN()
        // runDeploy(deployId, deployState)
    }

    // function resetState() {
    //     setDone([])
    //     setCurrentTopic(null)
    //     setCurrentMessages([])
    //     // setProcessing(true)
    //     // setWaitingMessage(true)
    //     // setDisabledFinish(true)        
    // }

    function onDeployRunHandler(deployState) {
        setDone([])

        // setCurrentTopic(null)
        // setCurrentMessages([])
        const startedTopic = 'deploytopic1:' + deployId
        const startedMsg = 'Preparing'
        setCurrentTopic(startedTopic);
        setCurrentMessages(messages => messages.concat([[startedTopic, startedMsg]]));

        setProcessing(true)
        setWaitingMessage(true)
        setDisabledFinish(true)
        checkDeployPhase(deployId).then((rs) => {
            const { data: result } = rs
            console.log("result", result)
            console.log("phase", result.data.phase)
            ////mqttRUN(result.data.phase)
            if (!result.data.is_running) {
                ////if (result.data.is_running === true) {
                mqttRUN(1)
                clearHistory(deployId)
                console.log("deployment is not running")
                runDeploy(deployId, deployState)
            } else {
                console.log("deployment is running, waiting message from mqtt")
                pickHistory(deployId)
                mqttRUN(result.data.phase)
            }
        }).catch(err => {
            console.log("phase.ERROR", err)
        }).finally(() => {
            console.log('DEPLOYMENT_FINISHED')
        })
    }


    // function clearHistoryTopic(deployId, topic) {
    //     localStorage.removeItem('last_topic_' + deployId)
    //     localStorage.removeItem('last_message_topic_' + deployId)
    // }

    function clearHistory(deployId) {
        localStorage.removeItem('last_topic_' + deployId)
        for (let i = 1; i <= 6; i++) {
            // last_message_topic_deploytopic2:0b7aac76-9632-43a5-bb12-dc0b9f933b42
            localStorage.removeItem('last_message_topic_deploytopic' + i + ':' + deployId)
        }
    }

    function pickHistory(deployId) {
        let lastTopic = localStorage.getItem('last_topic_' + deployId)
        if (lastTopic !== null) {
            const topicIndex = getTopicIndex(lastTopic)
            const index = topicIndex.substring(topicIndex.length - 1, topicIndex.length);
            console.log('topicIndex', index)
            if (index > 0) {
                for (let i = 1; i <= index; i++) {
                    console.log('TOPIC_', i)

                    // lastTopic = 'deploytopic1:0b7aac76-9632-43a5-bb12-dc0b9f933b42'
                    let lastTopicIndex = 'deploytopic' + i + ':' + deployId
                    console.log('LAST_TOPIC_INDEX_', lastTopicIndex)

                    let lastMsgTopic = localStorage.getItem('last_message_topic_' + lastTopicIndex)
                    console.log('LAST_MSG_TOPIC_INDEX_', lastMsgTopic)
                    const lastMessages = lastMsgTopic.split('+')
                    // const lastMessages = ['', 'A', 'B', 'C']
                    // console.log('MESSAGES', lastMessages)
                    lastMessages.forEach((message) => {
                        console.log('MESSAGE', message)
                        if (message != '') {
                            setCurrentMessages(messages => messages.concat([[lastTopicIndex, message]]));
                        }
                    })
                    setDone(done => done.concat([lastTopicIndex]))

                }
            }


            // // lastTopic = 'deploytopic1:0b7aac76-9632-43a5-bb12-dc0b9f933b42'
            // let lastMsgTopic = localStorage.getItem('last_message_topic_' + lastTopic)
            // console.log('LAST_TOPIC', lastTopic)
            // console.log('LAST_MSG_TOPIC', lastMsgTopic)
            // const lastMessages = lastMsgTopic.split('+')
            // // const lastMessages = ['', 'A', 'B', 'C']
            // console.log('MESSAGES', lastMessages)
            // lastMessages.forEach((message) => {
            //     console.log('MESSAGE', message)
            //     if (message != '') {
            //         setCurrentMessages(messages => messages.concat([[lastTopic, message]]));
            //     }
            // })
            // setDone(done => done.concat([lastTopic]))
        }
    }

    const cb = useRef();
    cb.current = props;

    useEffect(() => {
        // const onUnload = () => cb.current();
        const onUnload = () => {
            console.log('onUnload')
        };
        window.addEventListener("beforeunload", onUnload);

        if (deployId !== null) {
            setDisabledDeployBtn(true)
            // console.log('AUTO-RUN')
            // resetState()
            setTimeout(() => {
                // console.log('start deployment')
                onDeployRunHandler(1)
            }, 2000)
            // onDeployRunHandler(1)
        }

        return () => window.removeEventListener("beforeunload", onUnload);
    }, [cb]);

    // useEffect(() => {
    //     // const onUnload = () => cb.current();

    //     // console.log('deployId', deployId !== null && deployScenario === 2)
    //     console.log('deployScenario', deployScenario)
    //     console.log('isRunning', isRunning)
    //     // console.log('AUTO-RUN', deployId !== null && deployScenario === 2)
    //     // if (deployId !== null && deployScenario === 2 && isRunning === false) {
    //     //     //setDeployState(1)
    //     //     onDeployRunHandler(1)
    //     // }
    // }, [])

    function toggleExpand(topic) {
        setExpanded(expanded => {
            if (expanded != topic) {
                return topic
            }

            return expanded == null ? topic : null
        })
    }

    const deployDone = (done.indexOf("deploytopic6:" + deployId) != -1);

    return (
        <TabPanel {...props}>
            <div spacing={6} className={classes.containerTes}>
                <div className={classes.titleTes}>
                    We will now attempt to deploy your software based on your
                    configurations
            </div>
                <div>

                    {deployDone && (<Button
                        onClick={() => {
                            onReDeployHandler(1)
                        }}
                        color="primary"
                        variant="contained"
                        className={classes.btnTest}>
                        Re-deploy
                        </Button>
                    )}

                    {waitingMessage ? (
                        <>
                            <div>&nbsp;</div>
                        </>
                    ) : processing ? (
                        <>
                        </>
                    ) : (
                        <>
                            <Button
                                disabled={processing || disabledDeployBtn}
                                onClick={() => {
                                    onDeployRunHandler(1)
                                }}
                                color="primary"
                                variant="contained"
                                className={classes.btnTest}>
                                Deploy
                            </Button>

                        </>
                    )}

                </div>
            </div>
            <div className={classes.listTest}>
                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic1:" + deployId)}>{currentTopic == "deploytopic1:" + deployId ? <b>Checking prerequisite requirements & permissions</b> : "Checking prerequisite requirements & permissions"}</a>
                    <div>
                        {currentTopic == "deploytopic1:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic1:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic1:" + deployId || expanded == "deploytopic1:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic1:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : null}
                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic2:" + deployId)}>
                        {currentTopic == "deploytopic2:" + deployId ? <b>Validating deployment configurations</b> : "Validating deployment configurations"}
                    </a>
                    <div>
                        {currentTopic == "deploytopic2:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic2:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic2:" + deployId || expanded == "deploytopic2:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic2:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>

                ) : ''}
                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic3:" + deployId)}>{currentTopic == "deploytopic3:" + deployId ? <b>Provisioning the infrastructure</b> : "Provisioning the infrastructure"}</a>
                    <div>
                        {currentTopic == "deploytopic3:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic3:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic3:" + deployId || expanded == "deploytopic3:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic3:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : ''}
                {/* <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic4:" + deployId)}>{currentTopic == "deploytopic4:" + deployId ? <b>Configuring the infrastructure</b> : "Configuring the infrastructure"}</a>
                    <div>
                        {currentTopic == "deploytopic4:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic4:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic4:" + deployId || expanded == "deploytopic4:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic4:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : ''} */}

                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic4:" + deployId)}>{currentTopic == "deploytopic4:" + deployId ? <b>Deploying the build artifact</b> : "Deploying the build artifact"}</a>
                    <div>
                        {currentTopic == "deploytopic4:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic4:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic4:" + deployId || expanded == "deploytopic4:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic4:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : ''}

                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic5:" + deployId)}>{currentTopic == "deploytopic5:" + deployId ? <b> Configuring the application</b> : " Configuring the application"}</a>
                    <div>
                        {currentTopic == "deploytopic5:" + deployId ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic5:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {currentTopic == "deploytopic5:" + deployId || expanded == "deploytopic5:" + deployId ? (
                    <ul className={classes.messageBox}>
                        {
                            getMessagesForTopic(currentMessages, 'deploytopic5:' + deployId).map((message, i) => (
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : ''}

                <div className={classes.itemList}>
                    <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("deploytopic6:" + deployId)}>{currentTopic == "deploytopic6:" + deployId ? <b>Testing reachability of the application</b> : "Testing reachability of the application"}</a>
                    <div>
                        {currentTopic == "deploytopic6:" + deployId && done.indexOf("deploytopic6:" + deployId) == -1 ?
                            (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                            :
                            done.indexOf("deploytopic6:" + deployId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                        }
                    </div>
                </div>
                {(currentTopic == "deploytopic6:" + deployId && done.indexOf("deploytopic6:" + deployId) == -1) || expanded == "deploytopic6:" + deployId ? (
                    <ul className={classes.messageBox}>

                        {
                            getMessagesForTopic(currentMessages, 'deploytopic6:' + deployId).map((message, i) => (
                                // <li className={classes.messageItem}>&rarr; {message}</li>
                                <li key={i} className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                            ))
                        }
                    </ul>
                ) : ''}
            </div>

            {(done.indexOf("deploytopic6:" + deployId) != -1) ?
                <div className={classes.buildSuccess}>
                    <div className={classes.textBuildSuccess}>
                        Deployment Successful
                    </div>
                    <div>
                        <CheckCircleOutlineIcon className={classes.doneIcon} />
                    </div>
                </div>
                : error && (
                    <div className={classes.buildSuccess}>
                        <div className={classes.textBuildSuccess}>
                            Deployment Failed
                        </div>
                        <div>
                            <CancelIcon color="error" className={classes.doneIcon} style={{ color: 'red' }} />
                        </div>
                    </div>
                )}
            <div className={classes.containerBtnNavList}>
                <Grid container justify="space-between">
                    {deployScenario === 1 && (
                        <Button
                            color="primary"
                            disabled={processing}
                            variant="contained"
                            className={classes.btnNext}
                            onClick={() => onClickPrev()}
                            style={{ marginRight: '5px' }}>
                            <NavigateBeforeIcon />
                            <span className={classes.txtNext}>Prev</span>
                        </Button>
                    )}
                    {deployScenario === 2 && (
                        <Link to="/deploy/list">
                            <Button
                                //disabled={(done.indexOf("deploytopic7:" + deployId) == -1)}
                                color="primary"
                                variant="contained"
                                className={classes.btnNext}>
                                <NavigateBeforeIcon />
                                <span className={classes.txtNext}>Prev</span>
                            </Button>
                        </Link>
                    )}
                    <Link to="/deploy/list">
                        <Button
                            // disabled={(done.indexOf("deploytopic6:" + deployId) == -1)}
                            disabled={disabledFinish}
                            color="primary"
                            variant="contained"
                            className={classes.btnNext}>
                            <span className={classes.txtNext}>Finish</span>
                            <NavigateNextIcon />
                        </Button>
                    </Link>
                </Grid>
            </div>
        </TabPanel>
    );
};

Deploy.propTypes = {
    className: PropTypes.string
};

export default Deploy;
