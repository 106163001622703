import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { flexbox } from '@material-ui/system';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url(/loading.gif)',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  subroot: {
    alignItems: 'center'
  }
}));

const ProtectedRoute = ({ layout: Layout, component: Component, ...rest }) => {

  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token != null) {
      setIsAuthenticated(true)
    }

    setLoading(false)
  }, [])

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (loading)
          return (
            <div className={classes.root}></div>
          )

        if (!isAuthenticated)
          return <Redirect to="/sign-in" />

        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default ProtectedRoute;
