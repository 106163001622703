import { Button, Card, Typography, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import HomeIcon from '@material-ui/icons/Home';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { runBuild } from 'helpers/api';
import mqtt from 'mqtt';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ChooseBuild from './ChooseBuild';
import Configure from './Configure';
import Review from './Review';
import TabPanel from './TabPanel';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px',
        position: 'relative'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '12px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: "#000",
        padding: theme.spacing(2),
        listStyleType: 'none',
        fontFamily: 'Inconsolata, monospace',
        fontSize: '11px',
    },
    messageItem: {
        fontWeight: 'bold',
        color: '#fff'
    },
    messageItemError: {
        fontWeight: 'bold',
        color: '#cc3300',
    },
    titleConfigure: {
        fontSize: '12px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '12px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    },
    buttonProgress: {
        position: 'absolute'
    }
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const CreateBuild = props => {
    const { className, match } = props;

    const paramsBuildId = match.params.buildId
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(paramsBuildId == undefined ? 0 : 3);
    const [currentTopic, setCurrentTopic] = useState(null)
    const [currentMessages, setCurrentMessages] = useState([])
    const [done, setDone] = useState([])
    const [error, setError] = useState(false)
    const [expanded, setExpanded] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [buildId, setBuildId] = useState(Boolean(paramsBuildId) ? paramsBuildId : null)
    const [buildScenario, setBuildScenario] = useState(Boolean(paramsBuildId) ? 2 : 1)
    const [waitingMessage, setWaitingMessage] = useState(false);
    const [data, setData] = useState({
        envars: [],
        repo: null,
        branch: null,
        runtime: null,
        version: null,
        test: null,
        packageManager: null,
        preBuildCommands: [],
        postBuildCommands: []
    })

    useEffect(() => {
        console.log(paramsBuildId)
        if (paramsBuildId != undefined) {
            setValue(3)
            onBtnTestClicked()
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    function getMessagesForTopic(messages, topic) {
        return messages.filter(message => message[0] == topic).map(message => message[1]);
    }

    function mockData() {
        return {
            "websocketStatus": 1,
            "name": "My Awesome PHP",
            "repository": "https://github.com/tajhulfaijin/labphp001.git",
            "runtime": "php7.3",
            "packageManager": "composer",
            "testFramework": "php-unit",
            "preBuildCmd": "ls -l",
            "postBuildCmd": "echo 123",
            "status": "enabled"
        }
    }


    function onBtnTestClicked() {
        setProcessing(true)
        setWaitingMessage(true)

        const startedTopic = 'topic1:' + buildId
        const startedMsg = 'Preparing'
        setCurrentTopic(startedTopic);
        setCurrentMessages(messages => messages.concat([[startedTopic, startedMsg]]));

        //// mockData()
        const topics = [
            'topic1:' + buildId,
            'topic2:' + buildId,
            'topic3:' + buildId,
            'topic4:' + buildId,
            'topic5:' + buildId,
            'topic6:' + buildId,
            'topic7:' + buildId
        ];

        const client = mqtt.connect(process.env.REACT_APP_MQTT_HOST, {
            clientId: "clientID-" + parseInt(Math.random() * 100),
            port: process.env.REACT_APP_MQTT_PORT,
            username: process.env.REACT_APP_MQTT_USER,
            password: process.env.REACT_APP_MQTT_PASS
        });

        client.on('connect', () => {
            client.subscribe(topics, { qos: 2 })
        });

        client.on('message', (topic, msg) => {
            setWaitingMessage(false);
            const data = JSON.parse(msg.toString());

            setCurrentTopic(data.ID);
            setCurrentMessages(messages => messages.concat([[topic, data.Content]]));

            if (data.Status == 1 && done.indexOf(topic) == -1) {
                setDone(done => done.concat([topic]))
                if (topic == "topic7:" + buildId) {
                    setProcessing(false);
                }
            }

            if (data.Status == 2) {
                setCurrentMessages(messages => messages.concat([[topic, "Build failed."]]));
                setError(true)
                setProcessing(false)
                client.end(true);
            }
        })

        runBuild(buildId)

        //// testBuild(data)
    }

    function toggleExpand(topic) {
        setExpanded(expanded => {
            if (expanded != topic) {
                return topic
            }

            return expanded == null ? topic : null
        })
    }

    const configureDisabled = data.repo == null || data.branch == null || processing;
    const reviewDisabled = configureDisabled || data.runtime == null || data.version == null
    const testDisabled = buildId == null
    const buildDone = (done.indexOf("topic7:" + buildId) != -1);

    return (
        <Card className={clsx(classes.root, className)}>
            <Paper elevation={0} className={classes.breadcrumbs}>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link
                        color="inherit"
                        to="/home"
                        className={classes.link}>
                        <HomeIcon className={classes.icon} />
                        Home
          </Link>
                    <Link
                        color="inherit"
                        to="/build/list"
                        className={classes.link}>
                        <ViewStreamIcon className={classes.icon} />
                        Build List
          </Link>
                    <Typography color="textPrimary" className={classes.link}>
                        <ViewStreamIcon className={classes.icon} />
                        Build Create
          </Typography> */}
                </Breadcrumbs>
            </Paper>
            <AppBar
                position="static"
                color="default"
                style={{
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    marginLeft: '24px'
                }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example">
                    <Tab
                        disabled={processing || paramsBuildId}
                        label="Choose your build"
                        {...a11yProps(0)}
                        className={classes.menu}
                    />
                    <Tab label="Configure" disabled={configureDisabled} {...a11yProps(1)} className={classes.menu} />
                    <Tab label="Review" disabled={reviewDisabled} {...a11yProps(2)} className={classes.menu} />
                    <Tab label="Build" disabled={testDisabled} {...a11yProps(3)} className={classes.menu} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}>
                <ChooseBuild
                    value={value}
                    index={0}
                    dir={theme.direction}
                    style={{ minHeight: '365px' }}
                    onClickNext={(repo, branch) => {
                        console.log('REPO', repo, 'BRANCH', branch);
                        setData({ repo, branch })
                        setValue(1)
                    }} />
                <Configure value={value} index={1} dir={theme.direction}
                    onClickPrev={() => setValue(0)}
                    onClickNext={(runtime, version, test, packageManager, preBuildCommands, postBuildCommands, selectedTrigger, envars) => {
                        setData(data => ({ ...data, runtime, version, test, packageManager, preBuildCommands, postBuildCommands, selectedTrigger, envars }))
                        setValue(2)
                    }} />
                <Review value={value}
                    index={2}
                    dir={theme.direction}
                    data={data}
                    buildId={buildId}
                    onClickPrev={() => setValue(1)}
                    onClickNext={(buildId) => {
                        setBuildId(buildId)
                        setValue(3)
                    }} />
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <div spacing={6} className={classes.containerTes}>
                        <div className={classes.titleTes}>
                            We will now attempt to build your software based on your
                            configurations
            </div>
                        <div>
                            {waitingMessage ? (
                                <>
                                    <div>&nbsp;</div>
                                </>
                            ) : processing ? (
                                <>
                                    {/* <Button
                                        disabled={processing}
                                        onClick={onBtnTestClicked}
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnTest}>
                                        <div>Building</div>
                                        <CircularProgress size={20} className={classes.buttonProgress} />
                                    </Button> */}
                                </>
                            ) : (
                                        <>
                                            <Button
                                                disabled={processing}
                                                onClick={onBtnTestClicked}
                                                color="primary"
                                                variant="contained"
                                                className={classes.btnTest}>
                                                <div>Build</div>
                                            </Button>
                                        </>
                                    )}



                            {/* <Button
                                disabled={processing}
                                onClick={onBtnTestClicked}
                                color="primary"
                                variant="contained"
                                className={classes.btnTest}>
                                {waitingMessage ? (
                                    <>
                                        <div>Preparing</div>
                                        <CircularProgress size={20} className={classes.buttonProgress} />
                                    </>
                                ) : processing ? (
                                    <>
                                        <div>Building</div>
                                        <CircularProgress size={20} className={classes.buttonProgress} />
                                    </>
                                ) : 'Build'}
                            </Button> */}
                        </div>
                    </div>
                    <div className={classes.listTest}>
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic1:" + buildId)}>{currentTopic == "topic1:" + buildId ? <b>Setting up the build environment</b> : "Setting up the build environment"}</a>
                            <div>
                                {currentTopic == "topic1:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic1:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic1:" + buildId || expanded == "topic1:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic1:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : null}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic2:" + buildId)}>{currentTopic == "topic2:" + buildId ? <b>Pulling the source code</b> : "Pulling the source code"}</a>
                            <div>
                                {currentTopic == "topic2:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic2:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic2:" + buildId || expanded == "topic2:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic2:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>

                        ) : ''}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic3:" + buildId)}>{currentTopic == "topic3:" + buildId ? <b>Installing dependencies</b> : "Installing dependencies"}</a>
                            <div>
                                {currentTopic == "topic3:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic3:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic3:" + buildId || expanded == "topic3:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic3:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : ''}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic4:" + buildId)}>{currentTopic == "topic4:" + buildId ? <b>Executing pre-build commands</b> : "Executing pre-build commands"}</a>
                            <div>
                                {currentTopic == "topic4:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic4:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic4:" + buildId || expanded == "topic4:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic4:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : ''}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic5:" + buildId)}>{currentTopic == "topic5:" + buildId ? <b>Executing test scripts</b> : "Executing test scripts"}</a>
                            <div>
                                {currentTopic == "topic5:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic5:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic5:" + buildId || expanded == "topic5:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic5:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : ''}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic6:" + buildId)}>{currentTopic == "topic6:" + buildId ? <b>Creating build artifact</b> : "Creating build artifact"}</a>
                            <div>
                                {currentTopic == "topic6:" + buildId ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic6:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {currentTopic == "topic6:" + buildId || expanded == "topic6:" + buildId ? (
                            <ul className={classes.messageBox}>
                                {
                                    getMessagesForTopic(currentMessages, 'topic6:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : ''}
                        <div className={classes.itemList}>
                            <a className={classes.listTestText} href="javascript:void(0)" onClick={() => toggleExpand("topic7:" + buildId)}>{currentTopic == "topic7:" + buildId ? <b>Executing post-build commands</b> : "Executing post-build commands"}</a>
                            <div>
                                {currentTopic == "topic7:" + buildId && done.indexOf("topic7:" + buildId) == -1 ?
                                    (error ? <CloseIcon style={{ marginRight: '8px' }} /> : <CircularProgress size="10" className={classes.progress} />)
                                    :
                                    done.indexOf("topic7:" + buildId) != -1 ? <DoneIcon style={{ marginRight: '8px' }} /> : null
                                }
                            </div>
                        </div>
                        {(currentTopic == "topic7:" + buildId && done.indexOf("topic7:" + buildId) == -1) || expanded == "topic7:" + buildId ? (
                            <ul className={classes.messageBox}>

                                {
                                    getMessagesForTopic(currentMessages, 'topic7:' + buildId).map(message => (
                                        <li className={error ? classes.messageItemError : classes.messageItem}>&rarr; {message}</li>
                                    ))
                                }
                            </ul>
                        ) : ''}
                    </div>
                    {(done.indexOf("topic7:" + buildId) != -1) ?
                        <div className={classes.buildSuccess}>
                            <div className={classes.textBuildSuccess}>
                                Build Success
              </div>
                            <div>
                                <CheckCircleOutlineIcon className={classes.doneIcon} />
                            </div>
                        </div>
                        : error && (
                            <div className={classes.buildSuccess}>
                                <div className={classes.textBuildSuccess}>
                                    Build Failed
              </div>
                                <div>
                                    <CancelIcon color="error" className={classes.doneIcon} style={{ color: 'red' }} />
                                </div>
                            </div>
                        )}
                    <div className={classes.containerBtnNavList}>
                        <Grid container justify={"space-between"}>
                            <Button
                                disabled={processing}
                                color="primary"
                                variant="contained"
                                className={classes.btnNext}
                                style={{ marginRight: '5px' }}>
                                <NavigateBeforeIcon />
                                <span className={classes.txtNext}>Review</span>
                            </Button>
                            <Button
                                disabled={(done.indexOf("topic7:" + buildId) == -1)}
                                color="primary"
                                href="/build/list"
                                variant="contained"
                                className={classes.btnNext}>
                                <span className={classes.txtNext}>Finish</span>
                                <NavigateNextIcon />
                            </Button>
                        </Grid>
                    </div>
                </TabPanel>
            </SwipeableViews>
        </Card>
    );
};

CreateBuild.propTypes = {
    className: PropTypes.string
};

export default CreateBuild;
