import { Button, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
    Build,
    BuildFailed,
    BuildSuccess,
    BuildPercentage,
    TotalUsers,
    TasksProgress,
    TotalProfit,
    BuildChart,
    BuildByRuntime,
    LatestProducts,
    LatestOrders
} from './components';
import { getTotalBuild } from 'helpers/api';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
}));

const Home = () => {
    const classes = useStyles();

    // const [totalSuccess, setTotalSuccess] = useState(0)
    const [buildData, setBuildData] = useState(null)

    // const fetchBuildData = async () => {
    //     const response = await getTotalBuild()
    //     const { data } = response.data;
    //     console.log('build-data', data)
    //     setBuildData(data)
    // };

    useEffect(() => {
        console.log('OK')
        let mounted = true;
        const fetchBuildData = async () => {
            const response = await getTotalBuild()
            const { data } = response.data;
            console.log('build-data', data)
            setBuildData(data)
        };
        fetchBuildData()
        return () => {
            mounted = false;
        };        
    }, [])

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <BuildSuccess data={buildData} />
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <BuildFailed data={buildData} />
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <BuildPercentage data={buildData} />
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <Build data={buildData} />
                </Grid>                
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                >
                    <BuildChart />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                >
                    <BuildByRuntime />
                </Grid>
                {/* <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                >
                    <LatestProducts />
                </Grid> */}
                {/* <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                >
                    <LatestOrders />
                </Grid> */}
            </Grid>
        </div>
    );

};

export default Home;
