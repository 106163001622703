import { Button, Grid } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TabPanel from './TabPanel';
import { createBuild, createDeploy } from 'helpers/api';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-terminal";

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        border: '1px solid #90a4ae',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '14px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: "#000",
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    }
}));

const statusColors = {
    delivered: 'success',
    pending: 'info',
    refunded: 'danger'
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const Review = props => {
    const { data, onNext, deployId, onClickPrev } = props;

    console.log(data)

    const { build, configuration } = data;
    const preBuildCmd = configuration !== null ? configuration.prebuildCommands : ''
    const postBuildCmd = configuration !== null ? configuration.postbuildCommands : ''

    const classes = useStyles();
    const theme = useTheme();

    const handleNextClick = async () => {
        try {
            if (deployId == null) {
                // const { data: result } = await createDeploy({
                //     buildId: build.buildId,
                //     cloudInstance: configuration.instance.id,
                //     environment: configuration.environment.id,
                //     cloudOs: configuration.serviceOS.id,
                //     cloudProvider: configuration.cloudProvider.id,
                //     cloudProviderIcon: configuration.cloudProvider.icon,
                //     cloudService: configuration.service.id,
                //     cloudStorageSize: configuration.storageSize,
                //     cloudStorageType: configuration.storage.id
                // })
                const { data: result } = await createDeploy({
                    buildId: build.buildId,
                    cloudInstance: configuration.instance.id,
                    environment: configuration.environment.id,
                    cloudOs: '-',
                    cloudProvider: configuration.cloudProvider.id,
                    cloudProviderIcon: configuration.cloudProvider.icon,
                    cloudService: configuration.service.id,
                    cloudStorageSize: '-',
                    cloudStorageType: '-',
                    deploymentData: JSON.stringify({
                        'TF_VAR_app_environment': configuration.applicationEnv,
                        'TF_VAR_user_environment_variable': configuration.userEnv,
                        'TF_VAR_pre_deploy_cmd': preBuildCmd != '' ? preBuildCmd.split('\n') : [],
                        'TF_VAR_post_deploy_cmd': postBuildCmd != '' ? postBuildCmd.split('\n') : [],
                        'TF_VAR_region': configuration.selectedRegion,
                        'TF_VAR_public_key': configuration.publicKey,
                    }),
                    deploymentStrategy: configuration.selectedDeploymentStrategy,
                    cloudMinInstance: configuration.minInstance,
                    cloudMaxInstance: configuration.maxInstance,
                })
                console.log('deployID= ', result.deployId)
                onNext(result.deployId)
            } else {
                onNext(deployId)
            }

        } catch (error) {
            console.trace(error)
        }
    }

    if (build == null || configuration == null) {
        return <TabPanel {...props}></TabPanel>
    }

    console.log(build, configuration, configuration.prebuildCommands)
    console.log('preBuildCmd', preBuildCmd)
    console.log('postBuildCmd', postBuildCmd)
    // console.log('configuration.postBuildCommands', configuration.postBuildCommands)

    return (
        <TabPanel {...props}>
            <div className={classes.containerReview}>
                <div style={{ fontSize: '16px' }}>
                    <strong>Let’s review your build configuration:</strong>
                </div>
                <Grid container spacing={4} className={classes.titleBuild}>
                    <Grid item sm={6}>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Build Target</div>
                            <div className={classes.contentReview}>{data.build.name} {(data.build.id)}</div>
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Environment</div>
                            <div className={classes.contentReview}>
                                {configuration.environment.name}
                            </div>
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Cloud Provider</div>
                            <div className={classes.contentReview}>{configuration.cloudProvider.name}</div>
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Service</div>
                            <div className={classes.contentReview}>{configuration.service.name}</div>
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Region</div>
                            <div className={classes.contentReview}>
                                {configuration.selectedRegion}
                            </div>
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Instance Type</div>
                            <div className={classes.contentReview}>
                                {configuration.instance.name}
                            </div>
                        </div>


                        {configuration.service.id === 'elasticbeanstalk' ? (
                            <div className={classes.paddingContentReview}>
                                <div className={classes.labelReview}>Minimum Instances</div>
                                <div className={classes.contentReview}>
                                    {configuration.minInstance}
                                </div>
                            </div>
                        ) : null}
                        {configuration.service.id === 'elasticbeanstalk' ? (
                            <div className={classes.paddingContentReview}>
                                <div className={classes.labelReview}>Maximum Instances</div>
                                <div className={classes.contentReview}>
                                    {configuration.maxInstance}
                                </div>
                            </div>
                        ) : null}

                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Deployment Strategy</div>
                            <div className={classes.contentReview}>
                                {/* {configuration.selectedDeploymentStrategy.id + ' - ' + configuration.selectedDeploymentStrategy.name} */}
                                {configuration.selectedDeploymentStrategy}
                            </div>
                        </div>

                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>
                                Custom commands to execute before the deployment process
                            </div>
                            {/* {configuration.preBuildCommands !== undefined ? */}
                            <AceEditor
                                width="100%"
                                mode="sh"
                                readOnly={true}
                                highlightActiveLine={false}
                                maxLines={5}
                                theme="terminal"
                                // value={configuration.preBuildCommands.join('\n')}
                                value={preBuildCmd}
                                name="editor-prebuild"
                                editorProps={{ $blockScrolling: true }}
                            />
                            {/* : null} */}
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>
                                Custom commands to execute after the deployment process
                            </div>
                            {/* {configuration.postBuildCommands !== undefined ? */}
                            <AceEditor
                                width="100%"
                                mode="sh"
                                readOnly={true}
                                highlightActiveLine={false}
                                maxLines={5}
                                theme="terminal"
                                // value={configuration.postBuildCommands.join('\n')}
                                value={postBuildCmd}
                                name="editor-postbuild"
                                editorProps={{ $blockScrolling: true }}
                            />
                            {/* : null} */}
                        </div>


                        {/* <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Operating System</div>
                            <div className={classes.contentReview}>{configuration.serviceOS.name}</div>
                        </div> */}

                        {/* <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>
                                Custom commands to execute before the build process
                            </div>
                            {data.preBuildCommands.map(pre => (
                                <div className={classes.contentReview}>
                                    {' ' + pre}
                                </div>
                            ))}
                        </div>
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>
                                Custom commands to execute after the build process
                            </div>
                            {data.postBuildCommands.map(post => (
                                <div className={classes.contentReview}>
                                    {' ' + post}
                                </div>
                            ))}

                        </div> */}
                    </Grid>
                    <Grid item sm={4}>


                        {/* configuration.userEnv */}
                        {/* appEnv */}
                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Application Properties</div>
                            <div className={classes.contentReview}>
                                <table>
                                    <tbody>
                                        {configuration.applicationEnv.map((data, index) =>
                                            <tr key={index}>
                                                <td width="20%">{data.label}</td>
                                                <td width="2%">:</td>
                                                <td width="20%">{data.value}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Environment Variable</div>
                            <div className={classes.contentReview}>
                                <table>
                                    <tbody>
                                        {configuration.userEnv.map((data, index) =>
                                            <tr key={index}>
                                                <td width="20%">{data.key}</td>
                                                <td width="2%">:</td>
                                                <td width="20%">{data.value}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Storage Type</div>
                            <div className={classes.contentReview}>
                                {configuration.storage.name}
                            </div>
                        </div> */}
                        {/* <div className={classes.paddingContentReview}>
                            <div className={classes.labelReview}>Storage Size</div>
                            <div className={classes.contentReview}>
                                {configuration.storageSize} GB
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: theme.spacing(3) }}>
                <Grid
                    container
                    justify="space-between">
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.btnNext}
                        onClick={() => onClickPrev()}
                        style={{ marginRight: '5px' }}>
                        <NavigateBeforeIcon />
                        <span className={classes.txtNext}>Prev</span>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleNextClick}
                        variant="contained"
                        className={classes.btnNext}>
                        <span className={classes.txtNext}>Deploy</span>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </div>
        </TabPanel>
    );
};

Review.propTypes = {
    className: PropTypes.string
};

export default Review;
