import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Divider,
    Typography
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import { getBuildByRuntime } from 'helpers/api';
import { getRandomColor } from 'helpers/color';


const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    chartContainer: {
        position: 'relative',
        height: '300px'
    },
    stats: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    device: {
        textAlign: 'center',
        padding: theme.spacing(1)
    },
    deviceIcon: {
        color: theme.palette.icon
    }
}));

const BuildByRuntime = props => {
    const { className, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();
    const [buildData, setBuildData] = useState(null)
    const [buildContent, setBuildContent] = useState([])

    const fetchBuildData = async () => {
        const response = await getBuildByRuntime()
        const { data } = response.data;
        let devices = []
        let d = {}
        let dsData = []
        let dsBg = []
        let l = []
        data.forEach(e => {
            l.push(e.runtime)
            dsData.push(e.total)
            dsBg.push(getRandomColor())
            devices.push({
                title: e.runtime,
                value: e.total,
                icon: e.runtime_icon,
                color: theme.palette.primary.main
            })
        });
        d.labels = l
        d.datasets = [
            {
                data: dsData,
                backgroundColor: dsBg,
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
            }
        ]
        setBuildData(d)
        setBuildContent(devices)
    };

    function refresh(){
        fetchBuildData()
    }

    useEffect(() => {
        fetchBuildData()
    }, [])

    const options = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary
        }
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                action={
                    <IconButton onClick={()=>refresh()} size="small">
                        <RefreshIcon />
                    </IconButton>
                }
                title="Builds By Runtime"
            />
            <Divider />
            <CardContent>
                <div className={classes.chartContainer}>
                    <Doughnut
                        data={buildData}
                        options={options}
                    />
                </div>
                <div className={classes.stats}>
                    {buildContent.map(device => (
                        <div
                            className={classes.device}
                            key={device.title}
                        >
                            {/* <span className={classes.deviceIcon}>{device.icon}</span> */}
                            <span className={classes.deviceIcon}>
                                <img style={{
                                    widht: "36px",
                                    height: "36px",
                                }} src={device.icon} />
                            </span>
                            <Typography variant="body1">{device.title}</Typography>
                            <Typography
                                style={{ color: device.color }}
                                variant="h2"
                            >
                                {device.value}%
              </Typography>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

BuildByRuntime.propTypes = {
    className: PropTypes.string
};

export default BuildByRuntime;
