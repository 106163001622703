import { Card, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import HomeIcon from '@material-ui/icons/Home';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ChooseBuild from './ChooseBuild';
import Configure from './Configure';
import Deploy from './Deploy';
import Review from './Review';

const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3),
        // marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    menu: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    titleBuild: {
        paddingTop: theme.spacing(2)
    },
    separator: {
        width: '100%',
        border: '1px solid #E7E7E7'
    },
    separator2: {
        width: '50%',
        border: '1px solid #E7E7E7',
        marginBottom: '16px',
        marginTop: '16px'
    },
    buildOption: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between'
    },
    txtBuild: {
        paddingLeft: theme.spacing(2)
    },
    btnTest: {
        textTransform: 'none',
        padding: '3px 28px'
    },
    iconBuild: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    btnNext: {
        padding: '5px 15px',
        textTransform: 'none'
    },
    containerBtnNav: {
        bottom: '11px',
        display: 'flex',
        marginTop: '20%',
        justifyContent: 'center'
    },
    containerTes: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.divider,
        padding: theme.spacing(2)
    },
    titleTes: {
        paddingTop: '5px'
    },
    listTest: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        width: '73%',
        padding: theme.spacing(2)
    },
    itemList: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(1.5)
    },
    doneIcon: {
        fontSize: '40px',
        color: '#1EC66B'
    },
    buildSuccess: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%',
        paddingTop: '16px'
    },
    textBuildSuccess: {
        marginLeft: theme.spacing(5),
        fontWeight: 'bold',
        paddingTop: '11px'
    },
    containerBtnNavList: {
        bottom: '11px',
        display: 'flex',
        marginTop: '10%',
        justifyContent: 'center'
    },
    containerReview: {
        marginLeft: theme.spacing(2)
    },
    contentReview: {
        fontWeight: 'bold'
    },
    paddingContentReview: {
        paddingBottom: theme.spacing(2)
    },
    progress: {
        margin: theme.spacing(0, 1.4, 1.5, 0),
        width: 18,
        height: 18
    },
    wrapper: {
        position: 'relative'
    },
    paper: {
        top: 75,
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1)
    },
    btnContainer: {
        backgroundColor: 'white',
        border: 'none',
        width: '540px',
        cursor: 'pointer',
        '&:focus': {
            outlineColor: theme.palette.white
        }
    },
    selectContainer: {
        marginLeft: '24px',
        marginRight: '16px',
        paddingTop: '5px'
    },
    badges: {
        backgroundColor: 'rgb(207, 216, 220)',
        paddingLeft: '10px',
        borderRadius: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '12px',
        marginLeft: '10px'
    },
    selectCapt: {
        fontSize: '11px',
        color: '#9e9e9e',
        paddingTop: '4px'
    },
    messageBox: {
        backgroundColor: '#000',
        padding: theme.spacing(2),
        listStyleType: 'none'
    },
    messageItem: {
        color: '#fff'
    },
    titleConfigure: {
        fontSize: '12px',
        color: '#757575'
    },
    contentConfigure: {
        fontWeight: 'bold',
        fontSize: '12px'
    },
    iconConfigure: {
        fontSize: '14px',
        color: '#757575'
    },
    listTestText: {
        color: '#111'
    }
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const CreateDeploy = props => {
    const { className, match } = props;
    const paramsDeployId = match.params.deployId
    const paramsDeployState = match.params.deployState

    const classes = useStyles();
    const theme = useTheme();
    // const [value, setValue] = React.useState(0);
    const [value, setValue] = React.useState(paramsDeployId == undefined ? 0 : 3);
    const [build, setBuild] = useState(null)
    const [configuration, setConfiguration] = useState(null)
    // const [deployId, setDeployId] = useState(null)
    const [deployId, setDeployId] = useState(Boolean(paramsDeployId) ? paramsDeployId : null)
    const [deployScenario, setDeployScenario] = useState(Boolean(paramsDeployId) ? 2 : 1)
    const [isRunning, setIsRunning] = useState(Boolean(paramsDeployState) && paramsDeployState == 'R' ? true : false)

    useEffect(() => {
        setValue(1)
        // console.log(paramsDeployId)
        // console.log(paramsDeployState, isRunning)
        if (paramsDeployId != undefined) {
            setValue(3)
            // setValue(1)
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    //   const [orders] = useState(mockData);
    function handleClick(event) {
        event.preventDefault();
        alert('You clicked a breadcrumb.');
    }
    return (
        <Card className={clsx(classes.root, className)}>
            <Paper elevation={0} className={classes.breadcrumbs}>
                <Breadcrumbs aria-label="breadcrumb"></Breadcrumbs>
            </Paper>
            <AppBar
                position="static"
                color="default"
                style={{
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    marginLeft: '24px'
                }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example">
                    <Tab
                        label="Choose your build"
                        {...a11yProps(0)}
                        className={classes.menu}
                    />
                    <Tab disabled={build == null} label="Configure" {...a11yProps(1)} className={classes.menu} />
                    <Tab disabled={configuration == null} label="Review" {...a11yProps(2)} className={classes.menu} />
                    <Tab disabled={deployId == null} label="Deploy" {...a11yProps(3)} className={classes.menu} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}>
                <ChooseBuild value={value}
                    index={0}
                    onNext={build => {
                        setBuild(build)
                        setValue(1)
                    }}
                    dir={theme.direction}
                    style={{ minHeight: '365px' }} />
                <Configure
                    selectedBuild={{ build }}
                    onNext={configuration => {
                        setConfiguration(configuration)
                        setValue(2)
                    }}
                    value={value} index={1}
                    dir={theme.direction}
                    onClickPrev={() => setValue(0)}
                />
                <Review
                    value={value}
                    onNext={(deployId) => {
                        setDeployId(deployId)
                        setDeployScenario(1)
                        setValue(3)
                    }}
                    index={2}
                    dir={theme.direction}
                    data={{ build, configuration }}
                    deployId={deployId}
                    onClickPrev={() => setValue(1)}
                />
                <Deploy
                    value={value}
                    index={3}
                    dir={theme.direction}
                    deployScenario={deployScenario}
                    deployId={deployId}
                    isRunning={isRunning}
                    onClickPrev={() => setValue(2)}
                />
            </SwipeableViews>
        </Card>
    );
};

CreateDeploy.propTypes = {
    className: PropTypes.string
};

export default CreateDeploy;
