import { Avatar, Button, Card, CardActions, CardContent, Divider, Grid, Table, TableBody, TableCell, TablePagination, TableRow, Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LinkSharp from '@material-ui/icons/LinkSharp';
import LinkOff from '@material-ui/icons/LinkOff';
import AddIcon from '@material-ui/icons/Add';
import Computer from '@material-ui/icons/Computer';
import Search from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';

import { SearchInput } from 'components';
import {
    getMonitoringEc2,
    getMonitoringEB,
    getMonitoringEcs,
    getCredentialStatus,
    getDeployList,
    getDeployDetail,
    runDeploy
} from 'helpers/api';
// import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Offline, Online } from "react-detect-offline";
// import DestroyDialog from './DestroyDialog';
// import MultilineChart from '@material-ui/icons/MultilineChart';
// import { borderBottom } from '@material-ui/system';
import moment from 'moment';
import $ from 'jquery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    // root: {},
    row: {
        // height: '42px',
        // display: 'flex',
        // alignItems: 'center',
        marginTop: theme.spacing(5)
    },

    tablenavbar: {
        // minWidth: 800,
        marginTop: theme.spacing(7),
        padding: theme.spacing(2),
        // borderBottom: '1px solid #90a4ae',
    },

    // tableRow : {
    //     // border: '1px solid #90a4ae',
    //     // marginBottom: '100px',
    // },

    spacer: {
        flexGrow: 1
    },

    breadcrumbs: {
        padding: theme.spacing(2, 2),
        // border: '1px solid #90a4ae',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        margin: theme.spacing(3)
        // marginBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(1)
    },
    inner: {
        minWidth: 800
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    status: {
        marginRight: theme.spacing(1)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    avatarLoadingBtn: {
        // marginRight: theme.spacing(2),
        // marginLeft: theme.spacing(2)
        width: '36px',
        height: '36px',
    },
    link: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    txtMain: {
        fontSize: '12px',
        fontWeight: 'bold'
    },
    txtSub: {
        fontSize: '10px',
        color: '#90a4ae'
    },
    txtMainCards: {
        fontWeight: 'bold'
    },
    txtSubCards: {
        fontSize: '12px',
        color: '#90a4ae'
    },
    searchInput: {
        width: '50%',
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    btnNewBuild: {
        float: 'right',
        marginRight: theme.spacing(3)
    },
    txtBuild: {
        marginLeft: theme.spacing(1),
        textTransform: 'none'
    },
    headerCards: {
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(2)
    },
    containerGrid: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    cards: {
        paddingLeft: theme.spacing(2)
    },
    learnMore: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '5px'
    },
    iconFooter: {
        marginBottom: theme.spacing(1)
    },
    captPipeline: {
        fontSize: '10px',
        color: '#90a4ae'
    },
    titlePipeline: {
        fontWeight: 'bold'
    }
}));

const ListDeploy = props => {
    const { className, match } = props;

    const buildRunIcon = 'https://aku-io-static-assets.s3-ap-southeast-1.amazonaws.com/icon/Gear-0.2s-200px.gif'

    const classes = useStyles();

    const theme = useTheme();

    const [didMount, setDidMount] = useState(false);

    const [credentialVerified, setCredentialVerified] = useState(true);

    const [message, setMessage] = useState(null);

    const [data, setData] = useState({ deploys: [], meta: null });

    const [deployId, setDeployId] = useState(null);

    const [open, setOpen] = useState(false);

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue, deploy, index) => {
        setValue(newValue);
        console.log('newValue', newValue)
        console.log('deploy', deploy)
        console.log('index', index)
        if (newValue == 1) {
            console.log('fetch deployment detail')

            getDeployDetail(deploy.deploymentId).then(response => {
                // const { data } = response.data;
                const history = response.data.data
                console.log('data', history)
                const updated = Object.assign({}, data, {
                    deploys: data.deploys.map((b, i) => {
                        if (i === index) {
                            return Object.assign({}, b, {
                                history
                            })
                        }
                        return b
                    })
                })
                setData(updated)
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                console.log('fetch data done')
            })


            // const history = [{
            //     "deployment_no": 42,
            //     "status": "success",
            //     "duration": "425",
            //     "stack_trace": "ok"
            // },
            // {
            //     "deployment_no": 41,
            //     "status": "success",
            //     "duration": "495",
            //     "stack_trace": "ok"
            // }]
            // const updated = Object.assign({}, data, {
            //     deploys: data.deploys.map((b, i) => {
            //         if (i === index) {
            //             return Object.assign({}, b, {
            //                 // expanded: !b.expanded,
            //                 // monitorClicked: false,
            //                 history
            //             })
            //         }
            //         return b
            //     })
            // })
            // setData(updated)
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (terminate) => {
        console.log("terminate", terminate, deployId)
        if (terminate) {
            const deployState = 0
            runDeploy(deployId, deployState)
        }
        setOpen(false);
    };

    function handlePageChange(event, page) {
        fetchData(page + 1)
    };

    function fetchData(page = 1) {
        getDeployList(page).then(response => {
            const { data: deploys, meta } = response.data;
            setData({
                deploys: deploys.map(b => Object.assign(b, { detail: null, expanded: false, monitorClicked: false })),
                meta
            });
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            console.log('fetch data done')
            // Trigger click detail for selected deployId
            if (!!match.params.deployId) {
                $('#btd-' + match.params.deployId).click()
            }
            // fetchCredentialStatus()
        })
    }

    function fetchCredentialStatus() {
        getCredentialStatus().then(response => {
            console.log('response', response)
            setCredentialVerified(true)
        }).catch(err => {
            console.error(err)
            setCredentialVerified(false)
            setMessage("There's no cloud accounts linked to your account.")
        }).finally(() => {
            console.log('fetch credential status done')
        })
    }

    //terminateEnv
    async function terminateEnv(deploy, index) {
        // console.log(deploy)
        // console.log(index)
        // handleClickOpen()
        setOpen(true);
        setDeployId(deploy.deploymentId)
    }

    async function expand(deploy, index) {
        console.log('expand', index, deploy)
        let detail = deploy.detail
        const updated = Object.assign({}, data, {
            deploys: data.deploys.map((b, i) => {
                if (i === index) {
                    return Object.assign({}, b, {
                        expanded: false,
                        monitorClicked: !b.monitorClicked,
                        detail
                    })
                }
                return b
            })
        })
        setData(updated)

        // fetch data from api
        try {
            if (detail == null) {
                if (deploy.cloudService == 'ec2') {
                    const getMonitoring = await getMonitoringEc2(deploy.deploymentId)
                    // console.log('getMonitoring.data', getMonitoring.data)
                    const { data } = getMonitoring.data
                    detail = data
                    // console.log('detail', detail)
                }
                if (deploy.cloudService == 'elasticbeanstalk') {
                    const getMonitoring = await getMonitoringEB(deploy.deploymentId)
                    // console.log('getMonitoring.data', getMonitoring.data)
                    const { data } = getMonitoring.data
                    detail = data
                    // console.log('detail', detail)
                }
                if (deploy.cloudService == 'ecs') {
                    const getMonitoring = await getMonitoringEcs(deploy.deploymentId)
                    // console.log('getMonitoring.data', getMonitoring.data)
                    const { data } = getMonitoring.data
                    detail = data
                    // console.log('detail', detail)
                }
            }
            const updated = Object.assign({}, data, {
                deploys: data.deploys.map((b, i) => {
                    if (i === index) {
                        return Object.assign({}, b, {
                            expanded: !b.expanded,
                            monitorClicked: false,
                            detail
                        })
                    }
                    return b
                })
            })
            setData(updated)
        } catch (error) {
            console.error(error)
        }
    }

    function refreshList() {
        fetchData()
    }

    useEffect(() => {
        setDidMount(true);
        fetchData()
        fetchCredentialStatus()
        return () => setDidMount(false);
    }, [])

    if (!didMount) {
        return null;
    }

    return (
        <div className={clsx(classes.root, className)}>
            {/* <Card style={{
                // marginTop: theme.spacing(1)
            }}> */}
                
                <Grid item sm={12} style={{ padding: theme.spacing(2), }}>
                    {credentialVerified === false && (
                        <Alert severity="warning">{message}</Alert>
                    )}
                </Grid>


                <Grid
                    container
                    spacing={4}
                    style={{
                        // border:'1px solid',
                        marginTop : theme.spacing(1)
                    }}
                >
                    <Grid item sm={6}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search deploy"
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Link to="/deploy/create" >
                            <Button
                                disabled={!credentialVerified}
                                color="primary"
                                variant="contained"
                                className={classes.btnNewBuild}
                                style={{ marginRight: '5px' }}>
                                <AddIcon />
                                <span className={classes.txtBuild}>New Deploy</span>
                            </Button>
                        </Link>
                        <Button
                            onClick={refreshList}
                            color="secondary"
                            variant="contained"
                            className={classes.btnNewBuild} style={{ marginRight: '5px' }}>
                            <RefreshIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Online>
                    <CardContent className={classes.content}>
                        <PerfectScrollbar>
                            <div className={classes.inner}>
                                <Table
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#37474f',
                                        marginTop: theme.spacing(5)
                                    }}>
                                    <TableBody>
                                        {data.deploys.length === 0 ? (
                                            <TableRow hover>
                                                <TableCell colSpan="4" align="center">
                                                    <Typography className={classes.txtMain}>
                                                        No data available
                      </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                        {data.deploys.map((deploy, i) =>
                                            <Fragment key={i}>
                                                {/* <TableRow hover> */}
                                                <TableRow>
                                                    <TableCell
                                                        // style={{
                                                        //     'hover': { color: '#7EA5FF' }
                                                        // }}
                                                        onClick={() => expand(deploy, i)}
                                                    >
                                                        <div className={classes.nameContainer}>
                                                            <Avatar
                                                                className={classes.avatar}
                                                                src={deploy.status === 'running' || deploy.status === 'destroying' || deploy.status === 'provisioning' ? buildRunIcon : deploy.icon}></Avatar>
                                                            <div>
                                                                <Typography className={classes.txtMain}>
                                                                    {deploy.build_name}
                                                                </Typography>
                                                                <Typography className={classes.txtSub}>
                                                                    by{' '}
                                                                    <span
                                                                        style={{ fontWeight: 'bold', color: '#37474f' }}>
                                                                        {deploy.customerName}
                                                                    </span>
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography className={classes.txtMain}>
                                                            {deploy.name}
                                                        </Typography>
                                                        <Typography className={classes.txtSub}>
                                                            Deployment ID
                                                </Typography>
                                                    </TableCell>


                                                    <TableCell>
                                                        <Typography className={classes.txtMain}>
                                                            {deploy.cloudProvider.toUpperCase()} : {deploy.cloudService.toUpperCase()}
                                                        </Typography>
                                                        <Typography className={classes.txtSub}>
                                                            Service
                                                </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography className={classes.txtMain}>
                                                            {deploy.environment_url !== '' && (
                                                                <a target="_blank" rel="noopener noreferrer" href={deploy.environment_url}>
                                                                    <LinkSharp></LinkSharp>
                                                                </a>
                                                            )}
                                                            {deploy.environment_url === '' && (
                                                                <LinkOff></LinkOff>
                                                            )}
                                                        </Typography>
                                                        <Typography className={classes.txtSub}>
                                                            Endpoint
                                                </Typography>
                                                    </TableCell>


                                                    <TableCell>
                                                        <Typography className={classes.txtMain}>
                                                            {deploy.status.toUpperCase()}
                                                        </Typography>
                                                        <Typography className={classes.txtSub}>
                                                            Status
                                                    </Typography>
                                                    </TableCell>

                                                    {/* <TableCell>
                                                    <Typography className={classes.txtMain}>
                                                        Healthy
                                                    </Typography>
                                                    <Typography className={classes.txtSub}>
                                                        Last Health Check Status
                                                </Typography>
    
                                                </TableCell> */}


                                                    <TableCell>
                                                        {(['running', 'provisioning'].includes(deploy.status) && credentialVerified) && (
                                                            <Link to={"/deploy/create/" + deploy.deploymentId + "/R"}>
                                                                <Button color="primary" variant="outlined" size="small">
                                                                    VIEW
                                                            </Button>
                                                            </Link>
                                                        )}
                                                        {(['active', 'destroyed', 'terminated', 'failed'].includes(deploy.status) && credentialVerified) && (
                                                            <Link to={"/deploy/create/" + deploy.deploymentId + "/n"}>
                                                                <Button color="primary" variant="outlined" size="small">
                                                                    RUN
                                                            </Button>
                                                            </Link>
                                                        )}
                                                        {(['active'].includes(deploy.status) && credentialVerified) && (
                                                            <>
                                                                {' '}
                                                                <Button onClick={() => terminateEnv(deploy, i)} color="secondary" variant="outlined" size="small">
                                                                    TERMINATE
                                                        </Button>
                                                            </>
                                                        )}
                                                        {' '}
                                                        {/* {['active'].includes(deploy.status) && (
                                                            <Button id={'btd-' + deploy.deploymentId} 
                                                            color="secondary" variant="outlined" size="small">
                                                                
                                                                {deploy.monitorClicked ? (
                                                                    <>
                                                                    <CircularProgress size={20} /> DETAIL                                                                    
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {deploy.expanded ? (
                                                                            <>
                                                                                <ArrowDropUp size={20} /> DETAIL                                                                                
                                                                            </>                                                                            
                                                                        ): "DETAIL"}
                                                                    </>                                                                    
                                                                )}
                                                            </Button>
                                                        )} */}
                                                    </TableCell>
                                                </TableRow>
                                                {(deploy.expanded && deploy.detail != null) && (

                                                    <TableRow style={{ backgroundColor: '#eceff1' }}>
                                                        <TableCell colSpan="7">
                                                            <Table style={{ backgroundColor: 'white' }}>
                                                                <TableBody>

                                                                    <AppBar position="static">
                                                                        <Tabs value={value} onChange={(e, v) => {
                                                                            handleTabChange(e, v, deploy, i)
                                                                        }} aria-label="simple tabs example">
                                                                            <Tab icon={<Computer />} label="Monitoring" {...a11yProps(0)} />
                                                                            <Tab icon={<Search />} label="Activities" {...a11yProps(1)} />
                                                                        </Tabs>
                                                                    </AppBar>
                                                                    <TabPanel value={value} index={0}>
                                                                        {/* Monitoring */}
                                                                        {(deploy.cloudService == "ec2" || deploy.cloudService == "elasticbeanstalk") && (
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        CPU Utilization (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.cpu}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        Memory Utilization (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.memory}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        Network In/Out (Bytes)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.network_in}/{deploy.detail.network_out}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        Disk Reads/Writes (Bytes)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.disk_read}/{deploy.detail.disk_write}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}



                                                                        {(deploy.cloudService == "ecs") && (
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        CPU Utilization (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.cpu}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        Memory Utilization (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.memory}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        CPU Reservation (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.cpu_rsv}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>

                                                                                <Grid item xs={3}>
                                                                                    <Card className={clsx(classes.root, className)}>
                                                                                        <CardContent>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="space-between"
                                                                                            >
                                                                                                <Grid item>
                                                                                                    <Typography
                                                                                                        className={classes.title}
                                                                                                        color="textSecondary"
                                                                                                        gutterBottom
                                                                                                        variant="body2"
                                                                                                    >
                                                                                                        Memory Reservation (Percent)
                                                                                            </Typography>
                                                                                                    <Typography variant="h3">{deploy.detail.memory_rsv}</Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}


                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={1}>
                                                                        {/* deployment detail */}
                                                                        {/* {deploy.history !== undefined ? deploy.history.length : 'no'} */}
                                                                        <TableContainer >
                                                                            <Table className={classes.table} aria-label="simple table">
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>Date</TableCell>
                                                                                        <TableCell>Activity</TableCell>
                                                                                        <TableCell >Execution Time</TableCell>
                                                                                        <TableCell>Status</TableCell>
                                                                                        <TableCell>Stack Trace</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>

                                                                                    {deploy.history === undefined ? '' : deploy.history.map((history, i) => (
                                                                                        <Fragment key={i}>
                                                                                            <TableRow>
                                                                                                {/* <TableCell style={{ width: '3%' }}>
                                                                                        <div className={classes.captPipeline}>
                                                                                            <Typography className={classes.txtSub}>
                                                                                                No
                                                                                            </Typography>
                                                                                            #{history.deployment_no}
                                                                                        </div>
                                                                                    </TableCell> */}
                                                                                                <TableCell>
                                                                                                    {/* <Typography className={classes.txtSub}>
                                                                                            Date
                                                                                        </Typography> */}
                                                                                                    {moment(history.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {/* <Typography className={classes.txtSub}>
                                                                                            Activity
                                                                                        </Typography> */}
                                                                                                    {history.type}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {/* <Typography className={classes.txtSub}>
                                                                                            Execution Time
                                                                    </Typography> */}
                                                                                                    {history.duration + 's'}
                                                                                                </TableCell>
                                                                                                <TableCell align="left">
                                                                                                    {/* <Typography className={classes.txtSub}>
                                                                                            Status
                                                                    </Typography> */}
                                                                                                    {history.status === 'success' ? (
                                                                                                        <div style={{ display: 'flex', color: '#2e7d32' }}>
                                                                                                            <div style={{ marginRight: '4px' }}>
                                                                                                                <CheckCircleIcon
                                                                                                                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                                                                Successful
                                      </div>
                                                                                                        </div>
                                                                                                    ) : history.status === 'failed' ? (
                                                                                                        <div style={{ display: 'flex', color: '#c62828' }}>
                                                                                                            <div style={{ marginRight: '4px' }}>
                                                                                                                <ErrorIcon
                                                                                                                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                                                                Failed
                                      </div>
                                                                                                        </div>
                                                                                                    ) : ''}
                                                                                                </TableCell>

                                                                                                <TableCell>
                                                                                                    {/* <Typography className={classes.txtSub}>
                                                                                            Stack Trace
                                                                    </Typography> */}
                                                                                                    {history.stack_trace}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </Fragment>
                                                                                    ))}

                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>



                                                                    </TabPanel>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>

                                                )}
                                            </Fragment>
                                        )}
                                        {/* ))} */}

                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.actions}>
                        {data.meta && (
                            <TablePagination
                                component="div"
                                count={data.meta.total}
                                onChangePage={handlePageChange}
                                // onChangeRowsPerPage={handleRowsPerPageChange}
                                page={data.meta.current_page - 1}
                                rowsPerPage={data.meta.size_per_page}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        )}
                    </CardActions>
                </Online>
                <Offline>
                    <CardContent className={classes.content}>
                        <div className={classes.inner}>
                            <Table style={{ fontWeight: 'bold', color: '#37474f', marginTop: theme.spacing(5) }}>
                                <TableBody>
                                    <TableRow hover>
                                        <TableCell colSpan="4" align="center">
                                            <img src="https://img.icons8.com/ios-filled/50/000000/super-mario.png" />
                                            <Typography className={classes.txtMain}>
                                                You're offline right now. Check your connection.
                                          </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Offline>

                <>

                    <Dialog
                        open={open}
                        onClose={() => {
                            handleClose(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Terminate Environment"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This termination action cannot be undone. All data related to the environment will be lost. Are you sure?
          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                handleClose(false)
                            }} color="primary">
                                Cancel
          </Button>
                            <Button onClick={() => {
                                handleClose(true)
                            }} color="primary" autoFocus>
                                Terminate
          </Button>
                        </DialogActions>
                    </Dialog>
                </>

            {/* </Card> */}
        </div>


    );
};

ListDeploy.propTypes = {
    className: PropTypes.string
};

export default ListDeploy;
