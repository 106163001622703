
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { Divider, InputBase, Grid } from '@material-ui/core';
import ReactTooltip from 'react-tooltip'
import Help from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
    root: {},
    margin: {
        marginBottom: theme.spacing(2)
    },
    titleConfigure: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#263238'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    circle: {
        backgroundColor: '#fff',
        border: '1px solid red',
        height: '100px',
        width: '100px',
        borderRadius: '50%',
        MozBorderRadius : '50%',
        WebkitBorderRadius: '50%',
        // -moz - border - radius: 50 %;
        // -webkit - border - radius: 50 %;
        // width: 100px;
    }
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 5,
        marginLeft: theme.spacing(2),
        marginTop: '5px',
        // fontStyle: 'italic',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '150px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderColor: theme.palette.primary.main
        }
    }
}))(InputBase);

const ConfigureStackEnv = props => {
    // const { idx, onChangeValue, ...rest } = props;
    const { data, idx, onChangeValue, onBlurEnv, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {/* <BootstrapInput
                        type="text"
                        disabled
                        name="label"
                        defaultValue={data.label}
                    /> */}
                    <label>{data.label} &nbsp;
                    </label>
                </Grid>
                <Grid item xs={6}>
                    {data.required == 'true' ? (
                        <BootstrapInput
                            required
                            name={'n_' + idx + '_' + data.label}
                            id={data.key}
                            onChange={onChangeValue}
                            onBlur={onBlurEnv}
                            placeholder={data.hint}
                            type={data.type == 'string' ? 'text' : 'number'}
                            defaultValue={data.value}
                        />
                    ) : (
                            <BootstrapInput
                                name={'n_' + idx + '_' + data.label}
                                id={data.key}
                                onChange={onChangeValue}
                                onBlur={onBlurEnv}
                                placeholder={data.hint}
                                type={data.type == 'string' ? 'text' : 'number'}
                                defaultValue={data.value}
                            />

                        )}
                    &nbsp;
                    <a data-tip={data.hint} data-html={true}>
                        <span style={{
                            fontWeight: 'bold',
                            backgroundColor: '#263238',
                            color: '#fff',
                            paddingTop: '3px',
                            paddingBottom: '2px',
                            paddingLeft: '7px',
                            paddingRight: '7px',
                            borderRadius: '50%',
                            MozBorderRadius: '50%',
                            WebkitBorderRadius: '50%',
                        }}>&#63;</span>
                    </a>
                    <ReactTooltip place="left" html={true} multiline={true} />
                </Grid>
            </Grid>
            {/* <Divider className={classes.divider} /> */}
        </>
    );
};

ConfigureStackEnv.propTypes = {
    className: PropTypes.string
};

export default ConfigureStackEnv;
