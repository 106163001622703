import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout, ProtectedRoute } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
    //   Dashboard as DashboardView,
    //   ProductList as ProductListView,
    //   UserList as UserListView,
    //   Typography as TypographyView,
    //   Icons as IconsView,
    //   Account as AccountView,
    Settings as SettingsView,
    SignUp as SignUpView,
    SignIn as SignInView,
    NotFound as NotFoundView,
    Activate as ActivateView,
    Home as HomeView,
    BuildEmpty as BuildEmptydView,
    ListBuild as ListBuildView,
    CreateBuild as CreateBuildView,
    CreateDeploy as CreateDeployView,
    ListDeploy as ListDeployView,
    Notification as NotificationView,
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/home" />
            <ProtectedRoute
                component={SettingsView}
                exact
                layout={MainLayout}
                path="/settings"
            />
            <ProtectedRoute
                component={NotificationView}
                exact
                layout={MainLayout}
                path="/notifications"
            /> 
            <RouteWithLayout
                component={SignUpView}
                exact
                layout={MinimalLayout}
                path="/sign-up"
            />
            <RouteWithLayout
                component={ActivateView}
                exact
                layout={MinimalLayout}
                path="/activate"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
            />
            <ProtectedRoute
                component={HomeView}
                exact
                layout={MainLayout}
                path="/home"
            />
            <ProtectedRoute
                component={BuildEmptydView}
                exact
                layout={MainLayout}
                path="/build/empty"
            />
            <ProtectedRoute
                component={ListBuildView}
                exact
                layout={MainLayout}
                path="/build/list/:buildId?"
            />
            <ProtectedRoute
                component={CreateBuildView}
                exact
                layout={MainLayout}
                path="/build/create/:buildId?"
            />
            <ProtectedRoute
                component={CreateDeployView}
                exact
                layout={MainLayout}
                // path="/deploy/create/:deployId?"
                path="/deploy/create/:deployId?/:deployState?"
            />
            <ProtectedRoute
                component={ListDeployView}
                exact
                layout={MainLayout}
                path="/deploy/list/:deployId?"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
