import { AppBar, Badge, Hidden, IconButton, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { NotificationsPopover } from 'components';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { getNotification } from 'helpers/api';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none'
    },
    flexGrow: {
        flexGrow: 1
    },
    signOutButton: {
        marginLeft: theme.spacing(1)
    }
}));

const Topbar = props => {
    const { className, onSidebarOpen, history, ...rest } = props;

    const classes = useStyles();

    const [notifications, setNotifications] = useState([]);
    const [openNotifications, setOpenNotifications] = useState(false);

    const notificationsRef = useRef(null);

    const handleNotificationsOpen = () => {
        setOpenNotifications(true);
    };

    const handleNotificationsClose = () => {
        setOpenNotifications(false);
    };


    const onBtnLogoutClick = () => {
        // if (window.confirm('Are you sure want to logout?')) {
        //   localStorage.clear();
        //   history.push('/sign-in')
        // }
        handleClickOpen();
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        localStorage.clear();
        document.location.href = "/sign-in"
    };

    useEffect(() => {
        let mounted = true;
        let intervalId = null

        const fetchNotifications = () => {
            // axios.get('/api/account/notifications').then(response => {
            //     if (mounted) {
            //         setNotifications(response.data.notifications);
            //     }
            // });
            getNotification().then(response => {
                if (mounted) {
                    //console.log('response', response)
                    setNotifications(response.data.data);
                }
            });
        };

        fetchNotifications();

        intervalId = setInterval(fetchNotifications, 5000)

        // setNotifications([
        //     {
        //         'id' : 1,
        //         'type': 'build',
        //         'status': 'success',
        //         'title': 'Build OK',
        //         'created_at': '2020-01-01 10:00:00',
        //     },
        //     {
        //         'id': 1,
        //         'type': 'build',
        //         'status': 'running',
        //         'title': 'Build Run',
        //         'created_at': '2020-01-01 10:00:00',
        //     },
        //     {
        //         'id': 1,
        //         'type': 'build',
        //         'status': 'failed',
        //         'title': 'Build Fail',
        //         'created_at': '2020-01-01 10:00:00',
        //     },                        
        //     {
        //         'id': 2,
        //         'type': 'deploy',
        //         'status': 'success',
        //         'title': 'Deploy OK',
        //         'created_at': '2020-01-01 10:00:00',
        //     },
        //     {
        //         'id': 2,
        //         'type': 'deploy',
        //         'status': 'failed',
        //         'title': 'Deploy Error',
        //         'created_at': '2020-01-01 10:00:00',
        //     },
        //     {
        //         'id': 2,
        //         'type': 'deploy',
        //         'status': 'running',
        //         'title': 'Deploy Run',
        //         'created_at': '2020-01-01 10:00:00',
        //     }                                    
        // ]);

        return () => {
            mounted = false;
            clearInterval(intervalId);
        };
    }, []);



    return (
        <AppBar 
        //{...rest} 
        className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'LOGOUT'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to logout
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogin} color="primary">
                        Yes
          </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        No
          </Button>
                </DialogActions>
            </Dialog>
            <Toolbar>
                <RouterLink to="/">
                    <img alt="Logo" src="/images/logos/logo.png" />
                </RouterLink>
                <div className={classes.flexGrow} />
                <Hidden mdDown>

                    {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

                    <IconButton
                        className={classes.notificationsButton}
                        color="inherit"
                        onClick={handleNotificationsOpen}
                        ref={notificationsRef}
                    >
                        <Badge
                            badgeContent={notifications.length}
                            classes={{ badge: classes.notificationsBadge }}
                            variant="dot"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>


                    <NotificationsPopover
                        anchorEl={notificationsRef.current}
                        notifications={notifications}
                        onClose={handleNotificationsClose}
                        open={openNotifications}
                    />

                    <IconButton
                        className={classes.signOutButton}
                        onClick={onBtnLogoutClick}
                        color="inherit">
                        <InputIcon />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    history: PropTypes.object,
    onSidebarOpen: PropTypes.func
};

export default withRouter(Topbar);
